<template>
  <div class="position-brand-wrap">
    <div class="header">
      <h3>{{type === 1 ? '计划落位品牌管理' : '意向品牌管理'}}</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="content is-scroll-y-black">
      <div class="select-berth">
        <div class="info-title">{{type === 1 ? '落位铺位' : '意向落位铺位'}}</div>
        <div class="info-item">
          <div class="title">落位铺位 <span>*</span> </div>
          <div class="cascader-style height36-input">
            <el-cascader
              popper-class="black-select-panel"
              v-model='berthList'
              :options='floorList'
              size='small'
              @change='changeFloor'
            ></el-cascader>
          </div>
        </div>
        <div
          class="berth-info"
          v-if="berthData.berth"
        >
          <div class="area">建筑面积{{berthData.berth.buildingArea}}㎡，使用面积{{berthData.berth.useArea}}㎡</div>
          <div class="status">{{berthData.berth.useState === 1 ? '空置' : berthData.berth.useState === 2 ? '在营' : '失效'}}{{berthData.berth.taskState ? '/' + berthData.berth.taskState : ''}}</div>
        </div>
      </div>
      <div
        class="brand-wrap"
        v-if="berthId"
      >
        <div class="info-title">{{type === 1 ? '落位品牌' : '意向落位品牌'}}
          <el-tooltip
            class="item"
            popper-class="planTooltip"
            effect="dark"
            placement="top"
          >
            <span @click="addBrand()">{{type === 1 ? '添加落位品牌' : '添加意向品牌'}}</span>
            <div slot="content">{{type === 1 ? '添加落位品牌' : '添加意向品牌'}}</div>
          </el-tooltip>
        </div>
        <div class="brand-list">
          <div
            v-for="(item, index) in planBrands"
            :key="index"
          >
            <div
              class="brand-title"
              v-if="type === 2"
            >
              <span class="title">
                {{item.isFirst === 1 ? '首选落位品牌' : item.isFirst === 0 ? '备选落位品牌' : '意向落位品牌'}}
              </span>
              <!-- <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <span
                  class="add-btn"
                  v-if="index === 0"
                  @click="addBrand()"
                >添加意向品牌</span>
                <div slot="content">添加意向品牌</div>
              </el-tooltip> -->
            </div>
            <div class="brand-item">
              <span
                class="el-icon-close"
                v-if="(type === 1 && item.isFirst !== 2) || (type === 2 && item.isFirst === 2)"
                @click.stop="deleteDropBrnad(item.task.taskState, item.gbid, item.brand.brandName)"
              ></span>
              <div class="logo">
                <img :src="item.brand.brandLogo?item.brand.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'">
              </div>
              <div class="brand-info">
                <div
                  class="brand-name"
                  @click="toDetails(item)"
                >{{item.brand.brandName}}</div>
                <div class="format-name"> {{item.brand.industry}} <span>{{item.brand.brandClass}}</span> </div>
                <div class="status"> 品牌跟进状态：{{item.task.taskState}}
                  <span v-if="type === 1 && item.isFirst !== 2">首选落位：
                    <el-switch
                      v-model="item.isFirst"
                      active-color="#13ce66"
                      inactive-color="#808191"
                      :active-value="1"
                      :inactive-value="0"
                      :disabled="switchDisadled || item.isFirst === 1"
                      @change="switchChange(index, item.isFirst)"
                    >
                    </el-switch>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="brand-zero"
          v-if="planBrands && planBrands.length === 0 && !isAddBrand"
        >
          <div>
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>未添加落位品牌</p>
          </div>
        </div>
        <div
          class="add-brand-wrap"
          v-if="isAddBrand"
        >
          <span
            class="el-icon-close"
            @click="hideNewAddBrand"
          ></span>
          <div class="logo">
            <img
              v-if="selectBrand.brandLogo"
              :src="selectBrand.brandLogo"
            >
            <img
              v-else
              src="@/assets/images/black-lazy.png"
            >
          </div>
          <div class="brand-info">
            <div class="search-brand-name-autocomplete">
              <el-autocomplete
                v-model="brandName"
                class="w-input-sty store-input input-solid"
                popper-class="yellow-select-panel"
                reserve-keyword
                hide-loading
                size='mini'
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入品牌关键字"
                @select="handleSelect"
              ></el-autocomplete>
            </div>
            <div
              class="not-search-brand"
              v-if="isToAddBrandBtn"
            > 暂无搜索到此品牌 <span @click="toAddBrand">去添加</span> </div>
            <div
              class="status"
              v-if="type === 1"
            >
              <span>首选落位</span>
              <el-switch
                v-model="isFirst"
                active-color="#13ce66"
                inactive-color="#808191"
              >
              </el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        v-if="type === 1"
        :class="[berthId === '' || isDisabled ? 'isDisabled' : 'sure']"
        @click="sure()"
      >保存</span>
      <span
        v-if="type === 2"
        :class="[berthId === '' || isDisabled ? 'isDisabled' : 'sure']"
        @click="sure()"
      >保存</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  props: {
    brandDetails: {
      type: Object
    },
    projectId: {
      type: Number
    },
    recommendObj: {
      type: Object
    },
    dropEdit: {
      type: Number
    },
    delset: {
      type: Boolean,
      default: true
    },
    type: {
      type: Number
    },
    decisionId: {
      type: Number
    }
  },
  data () {
    return {
      isAddBrand: false,
      isFirst: 1,
      brandName: '',
      berthId: '',
      berthList: [],
      floorList: [],
      berthData: {},
      selectBrand: {},
      planBrands: [],
      searchList: [],
      brandLibrary: [],
      isToAddBrandBtn: false,
      isDisabled: false,
      switchDisadled: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  watch: {
    planBrands (n) {
      if (this.type === 1) {
        if (n && n.length === 1) {
          n = n.map((item, idx) => {
            item.isFirst = 1
            return item
          })
          this.switchDisadled = true
        } else {
          this.switchDisadled = false
        }
      }
    }
  },
  mounted () {
    this.brandName = ''
    this.isAddBrand = false
    // console.log('this.projectId', this.projectId)
    // console.log('this.dropEdit', this.dropEdit)
    // console.log('this.recommendObj', this.recommendObj)
    // console.log('this.brandDetails', this.brandDetails)
    // console.log('this.type', this.type)
    this.brandLibrary = this.user.confIds.filter(item => {
      return item.id === 164
    })
    if (JSON.stringify(this.brandDetails) !== '{}') {
      this.berthList.push(this.brandDetails.berth.building)
      this.berthList.push(this.brandDetails.berth.floor)
      this.berthList.push(this.brandDetails.berth.berthId)
      this.berthId = this.brandDetails.berth.berthId
      this.getShopPlanList()
    }
    this.getFloorList()
  },
  methods: {
    toDetails (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    switchChange (index, isFirst) {
      this.planBrands = this.planBrands.map((item, idx) => {
        if (item.isFirst !== 2) {
          if (index === idx) {
            item.isFirst = isFirst
          } else {
            item.isFirst = 0
          }
        }
        return item
      })
      this.isDisabled = false
    },
    getShopPlanList () {
      this.planBrands = []
      this.axios.post(api.getMapBrandDetails, { berthId: this.berthId, decisionId: this.decisionId || 0 })
        .then((res) => {
          this.berthData = res.data.data
          if (this.type === 1) {
            this.planBrands = this.berthData.planBrands.filter(item => {
              return item.isFirst !== 2
            })
          } else if (this.type === 2) {
            this.planBrands = this.berthData.planBrands
          }
          if (JSON.stringify(this.recommendObj) !== '{}') {
            // this.isAddBrand = true
            // this.isDisabled = false
            // this.selectBrand = this.recommendObj
            // this.brandName = this.recommendObj.brandName
            const brand = {}
            const task = {
              taskState: '待招商'
            }
            brand.brandName = this.recommendObj.brandName
            brand.brandLogo = this.recommendObj.brandLogo
            brand.brandId = this.recommendObj.brandId
            brand.industry = this.recommendObj.typeNames
            brand.brandClass = this.recommendObj.brandClass
            brand.gbid = this.recommendObj.gbid
            brand.taskState = '待招商'
            this.selectBrand.brand = brand
            this.selectBrand.task = task
            if (this.type === 1) {
              this.selectBrand.isFirst = 0
            } else {
              this.selectBrand.isFirst = 2
            }
            this.planBrands.push(this.selectBrand)
            this.isAddBrand = false
            this.isDisabled = false
          } else {
            this.isDisabled = true
          }
        })
    },
    getFloorList () {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.floorList = res.data.data
        })
    },
    changeFloor (val) {
      this.berthId = val[2]
      this.selectBrand = {}
      this.brandName = ''
      this.getShopPlanList()
    },
    querySearchAsync (queryString, cb) {
      if (queryString) {
        this.axios.post(api.searchBrandForCloud, { brandName: queryString, projectId: this.projectId })
          .then((res) => {
            this.searchList = res.data.data.map(item => {
              item.value = item.brandName
              item.id = item.gbid
              return item
            })
            if (this.searchList && this.searchList.length > 0) {
              this.isToAddBrandBtn = false
            } else {
              this.isToAddBrandBtn = true
            }
            cb(this.searchList)
          })
      } else {
        this.searchList = []
        this.isToAddBrandBtn = false
        cb(this.searchList)
      }
    },
    handleSelect (data) {
      let flag = true
      for (const list of this.planBrands) {
        if (list.brand.brandName.length === data.brandName.length && list.brand.brandName.includes(data.brandName)) {
          flag = false
        }
      }
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '该品牌已添加，请选择其他品牌！'
        })
        this.brandName = ''
        return
      }
      this.selectBrand = {}
      this.selectBrand = data
      const brand = {}
      const task = {
        taskState: data.taskState || '待招商'
      }
      if (data.brandId === 0) {
        if (this.type === 1) {
          this.checkIsToBerth(data).then(res1 => {
            if (res1.data.code === 0) {
              this.selectBrand.gbid = data.gbid
              this.selectBrand.isFirst = 0
              brand.gbid = data.gbid
              brand.brandName = data.brandName
              brand.brandLogo = data.brandLogo
              brand.brandId = data.brandId
              brand.industry = data.typeName
              brand.brandClass = data.brandClass
              brand.taskState = data.taskState || '待招商'
              this.selectBrand.brand = brand
              this.selectBrand.task = task
              this.planBrands.push(this.selectBrand)
              this.isAddBrand = false
              this.isDisabled = false
            } else {
              this.selectBrand = {}
              this.brandName = ''
            }
          })
        } else {
          this.selectBrand.gbid = data.gbid
          this.selectBrand.isFirst = 2
          brand.gbid = data.gbid
          brand.brandName = data.brandName
          brand.brandLogo = data.brandLogo
          brand.brandId = data.brandId
          brand.industry = data.typeName
          brand.brandClass = data.brandClass
          brand.taskState = data.taskState || '待招商'
          this.selectBrand.brand = brand
          this.selectBrand.task = task
          this.planBrands.push(this.selectBrand)
          this.isAddBrand = false
          this.isDisabled = false
        }
      } else {
        this.checkEnterpriseTabelBrandDataFunc(data).then((res) => {
          if (res.data > 0) {
            if (this.type === 1) {
              this.checkIsToBerth(data).then(res1 => {
                if (res1.data.code === 0) {
                  this.selectBrand.gbid = res.data
                  this.selectBrand.isFirst = 0
                  brand.gbid = res.data
                  brand.brandName = data.brandName
                  brand.brandLogo = data.brandLogo
                  brand.brandId = data.brandId
                  brand.industry = data.typeName
                  brand.brandClass = data.brandClass
                  brand.taskState = data.taskState || '待招商'
                  this.selectBrand.brand = brand
                  this.selectBrand.task = task
                  this.planBrands.push(this.selectBrand)
                  this.isAddBrand = false
                  this.isDisabled = false
                } else {
                  this.selectBrand = {}
                  this.brandName = ''
                }
              })
            } else {
              this.selectBrand.gbid = res.data
              this.selectBrand.isFirst = 2
              brand.gbid = res.data
              brand.brandName = data.brandName
              brand.brandLogo = data.brandLogo
              brand.brandId = data.brandId
              brand.industry = data.typeName
              brand.brandClass = data.brandClass
              brand.taskState = data.taskState || '待招商'
              this.selectBrand.brand = brand
              this.selectBrand.task = task
              this.planBrands.push(this.selectBrand)
              this.isAddBrand = false
              this.isDisabled = false
            }
          } else {
            this.$confirm('该品牌将同步到企业品牌库，是否确认？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              showClose: false,
              customClass: 'invitePosition'
            }).then((res) => {
              if (res === 'confirm') {
                this.axios.post(api.exportBrandToEnterpriseBrandTableSingular, {
                  brandId: data.brandId
                })
                  .then((res) => {
                    if (res.data.code === 0) {
                      this.selectBrand.gbid = res.data.data
                      if (this.type === 1) {
                        this.selectBrand.isFirst = 0
                      } else {
                        this.selectBrand.isFirst = 2
                      }
                      brand.gbid = res.data.data
                      brand.brandName = data.brandName
                      brand.brandLogo = data.brandLogo
                      brand.brandId = data.brandId
                      brand.industry = data.typeName
                      brand.brandClass = data.brandClass
                      brand.taskState = data.taskState
                      this.selectBrand.brand = brand
                      this.selectBrand.task = task
                      this.planBrands.push(this.selectBrand)
                      this.isAddBrand = false
                      this.isDisabled = false
                    } else {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg
                      })
                      this.selectBrand = {}
                      this.brandName = ''
                    }
                  })
              }
            }).catch((err) => {
              if (err === 'cancel') {
                this.selectBrand = {}
                this.brandName = ''
              }
            })
          }
        })
      }
    },
    checkIsToBerth (data) {
      const params = {
        gbid: data.gbid - 0,
        berthId: this.berthId
      }
      return new Promise((resolve) => {
        this.axios.get(api.checkIsThrough, { params: params })
          .then((res) => {
            console.log(res)
            resolve(res)
          })
      })
    },
    addBrand () {
      if (this.isAddBrand) {
        this.$message({
          type: 'warning',
          message: '请先完善当前落位品牌设置，再添加新品牌！'
        })
        return false
      } else {
        this.selectBrand = {}
        this.brandName = ''
        this.isAddBrand = true
        this.isDisabled = true
      }
    },
    hideNewAddBrand () {
      this.isAddBrand = false
      this.isDisabled = false
    },
    sure () {
      if (!this.berthId) {
        this.$message({
          type: 'warning',
          message: '请先选择要落位的铺位！'
        })
        return
      }
      if (this.type === 1) {
        if (this.isDisabled || this.berthId === '') {
          return
        }
      }
      if (this.type === 2) {
        if (this.berthId === '' || this.isDisabled) {
          return
        }
      }
      const params = {}
      const data = []
      for (const item of this.planBrands) {
        if (this.type === 1) {
          if (item.isFirst !== 2) {
            const obj = {}
            obj.berthId = this.berthId
            obj.brandId = item.brand.brandId
            obj.brandName = item.brand.brandName
            obj.gbid = item.brand.gbid
            if (this.type === 2) {
              obj.isFirst = 2
            } else {
              obj.isFirst = item.isFirst
            }
            data.push(obj)
          }
        } else {
          if (item.isFirst === 2) {
            const obj = {}
            obj.berthId = this.berthId
            obj.brandId = item.brand.brandId
            obj.brandName = item.brand.brandName
            obj.gbid = item.brand.gbid
            if (this.type === 2) {
              obj.isFirst = 2
            } else {
              obj.isFirst = item.isFirst
            }
            data.push(obj)
          }
        }
      }
      params.childList = data
      params.berthId = this.berthId
      if (this.type === 1) {
        params.decisionId = this.decisionId
      } else {
        params.decisionId = 0
      }
      params.projectId = this.projectId
      params.type = this.type
      this.axios.post(api.batchsaveBerthplan, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$emit('hidePositionBrand', true)
          }
        })
    },
    checkEnterpriseTabelBrandDataFunc (item) {
      return new Promise((resolve) => {
        this.axios.post(api.checkEnterpriseTabelBrandData, {
          brand_id: item.brandId,
          brandName: item.brandName
        })
          .then((res) => {
            resolve(res.data)
          })
      })
    },
    // 删除落位品牌
    deleteDropBrnad (state, id, brandName) {
      if (this.type === 1) {
        if (!this.delset) {
          this.$message({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      }
      if (state === '待招商' || state === '无意向' || state === '已搁置') {
        // 删除落位不提示
        this.$confirm('确定要删除"' + brandName + '"吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.planBrands = this.planBrands.filter(options => {
            return options.gbid !== id
          })
          this.isDisabled = false
        })
      } else if (state === '已联系' || state === '已报价' || state === '已预定' || state === '已看场' || state === '待跟进') {
        // 删除落位要提示
        this.$confirm('当前落位品牌正在招商中', '确定要删除"' + brandName + '"吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.planBrands = this.planBrands.filter(options => {
            return options.gbid !== id
          })
          this.isDisabled = false
        })
      } else if (state === '已签约') {
        // 删除签约删除落位要提示
        this.$confirm('当前落位品牌已经签约，删除后将同步删除签约数据', '确定要删除"' + brandName + '"吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.planBrands = this.planBrands.filter(options => {
            return options.gbid !== id
          })
          this.isDisabled = false
        })
      }
    },
    close () {
      this.recommendObj = {}
      this.$emit('hidePositionBrand')
    },
    toAddBrand () {
      if (this.brandLibrary[0].func.indexOf('add') === -1) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
      } else {
        if (document.domain.indexOf('local.winshangdata') > -1) {
          window.open('http://' + document.domain + ':8030/brand/#/checkBrand')
        } else {
          window.open(this.$gaodeDomain + `/omsviews/rent/assets/enterprise?menuCode=11&tokenval=${this.token}&type=checkBrand`)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.info-title
  position relative
  font-size 16px
  padding-left 7px
  color #fff
  &:after
    content ''
    display inline-block
    position absolute
    left 0px
    top 5px
    width 2px
    height 14px
    background #F89407
    z-index 11
  span
    float right
    color #F89407
    font-size 14px
    cursor pointer
.position-brand-wrap
  height 100%
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
.content
  padding 32px 24px
  margin-bottom 160px
  height calc(100% - 190px)
  max-height 800px
  overflow-y auto
  .select-berth
    .info-item
      display flex
      height 36px
      align-items center
      margin-top 14px
      padding-left 7px
      .title
        color #B2B3BD
        font-size 14px
        margin-right 6px
        span
          color #EE2D41
    .berth-info
      display flex
      padding-left 78px
      margin-top 16px
      .area
        font-size 14px
        color #808191
      .status
        font-size 14px
        color #fff
        margin-left 24px
  .brand-wrap
    margin-top 32px
    .brand-list
      margin-top 18px
      .brand-title
        margin-bottom 14px
        display flex
        .title
          font-size 14px
          font-weight 400
          color #FFFFFF
        .add-btn
          margin-left auto
          font-size 14px
          font-weight 400
          color #F89407
          cursor pointer
      .brand-item
        height 84px
        background #1E1E23
        position relative
        display flex
        align-items center
        margin-bottom 24px
        border-radius 2px
        .el-icon-close
          position absolute
          right 10px
          top 10px
          cursor pointer
          color #d9d9d9
          font-size 14px
        .logo
          width 97px
          height 64px
          img
            width 77px
            height 64px
            border-radius 2px
            margin 0 12px 0 8px
        .brand-info
          color #fff
          .brand-name
            color #fff
            font-size 14px
            text-decoration underline
            cursor pointer
            &:hover
              color #f89407
          .format-name
            color #808191
            font-size 12px
            margin-top 4px
            margin-bottom 4px
            span
              margin-left 12px
          .status
            color #808191
            font-size 12px
            span
              margin-left 24px
.add-brand-wrap
  height 84px
  background #1E1E23
  position relative
  display flex
  margin-bottom 24px
  padding-top 10px
  border-radius 2px
  .el-icon-close
    position absolute
    right 10px
    top 10px
    cursor pointer
    color #d9d9d9
    font-size 14px
  .logo
    img
      width 77px
      height 64px
      border-radius 2px
      margin 0 12px 0 8px
  .brand-info
    color #fff
    position relative
    .not-search-brand
      border-top 1px solid rgba(255, 255, 255, 0.1)
      position absolute
      left 0
      top 28px
      width 200px
      height 32px
      color #fff
      font-size 12px
      line-height 32px
      padding-left 8px
      background-color #373a43
      z-index 11
      span
        margin-left 10px
        color #f89407
        cursor pointer
    .status
      color #808191
      font-size 12px
      margin-top 10px
      height 22px
      display flex
      align-items center
      span
        margin-right 10px
        margin-left 100px
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 150px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      background-color #f89407
      color #fff
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
      color rgba(255, 255, 255, 0.5)
.brand-zero
  width 100%
  height 175px
  background-color #1E1E23
  border-radius 2px
  text-align center
  display flex
  align-items center
  justify-content center
  img
    width 137px
    height 99px
  p
    margin-top 8px
    color #808191
    font-size 14px
    font-weight 500
</style>
