<template>
  <div class="brand-details">
    <div class="map-details">
      <div class="details-title">
        <span
          :class="[titleIdx === 1 ? 'active': '']"
          @click="titleClick(1)"
        >铺位落位详情</span>
        <span
          v-if="type === 1"
          :class="[titleIdx === 2 ? 'active': '']"
          @click="titleClick(2)"
        >品牌智能匹配</span>
        <i
          class="el-icon-close"
          @click="hideMapDetails()"
        ></i>
      </div>
      <div
        class="details-content"
        v-if="titleIdx === 1"
      >
        <div class="berth-info">
          <div class="title">
            铺位信息 <i @click="toBerthEdit()">铺位管理</i>
          </div>
          <div class="berth-msg">
            <p><span><em>楼宇楼层：</em>{{brandDetails.berth.building}}/{{brandDetails.berth.floor}}</span><span><em>铺位ID：</em>{{brandDetails.berth.berthId}}</span></p>
            <p><span
                class="ellipsis"
                :title="brandDetails.berth.berthNo"
              ><em>铺位号：</em>{{brandDetails.berth.berthNo}}</span><span class="ellipsis"><em>铺位使用状态：</em>{{brandDetails.berth.useState === 1 ? '空置' : brandDetails.berth.useState === 2 ? '在营' : '失效'}}</span></p>
            <p><span><em>建筑面积：</em>{{brandDetails.berth.buildingArea?brandDetails.berth.buildingArea+'㎡':'-'}}</span><span><em>使用面积：</em>{{brandDetails.berth.useArea?brandDetails.berth.useArea+'㎡':'-'}}</span></p>
          </div>
        </div>
        <div class="berth-info">
          <div class="title">
            租决信息 <i
              @click="toRentPlanEdit"
              v-if="type === 1"
            >租金计划管理</i>
          </div>
          <div class="berth-msg">
            <p>
              <span><em>规划业态：</em>{{brandDetails.planBerthRentInfo.industry1?brandDetails.planBerthRentInfo.industry1:'-'}}</span>
              <span><em>主次力店：</em>{{brandDetails.planBerthRentInfo.mainStore === null ? '-' : brandDetails.planBerthRentInfo.mainStore === 0 ? '普通店' : (brandDetails.planBerthRentInfo.mainStore === 1 ? '主力店' : '次主力店')}}</span>
            </p>
            <p>
              <span><em>计租面积类型：</em>{{!brandDetails.planBerthRentInfo.areaType ? '-' : brandDetails.planBerthRentInfo.areaType === 1 ? '建筑面积' : '使用面积'}}</span>
              <span><em>计租面积：</em>{{brandDetails.planBerthRentInfo.area !== '-' && brandDetails.planBerthRentInfo.area ? brandDetails.planBerthRentInfo.area + '㎡':'-'}}</span>
            </p>
            <p>
              <span><em>租赁年限：</em>{{brandDetails.planBerthRentInfo.rentYear?brandDetails.planBerthRentInfo.rentYear: '-'}}</span>
              <span class="ellipsis"><em>租金递增率：</em>{{brandDetails.planBerthRentInfo.rentIncreasingRate?brandDetails.planBerthRentInfo.rentIncreasingRate:'-'}}</span>
            </p>
            <p>
              <span><em>租金市场价：</em>{{brandDetails.planBerthRentInfo.marketPrice?brandDetails.planBerthRentInfo.marketPrice + brandDetails.planBerthRentInfo.unit :'-'}}</span>
              <span><em>租金标准价：</em>{{brandDetails.planBerthRentInfo.rentPrice?brandDetails.planBerthRentInfo.rentPrice + brandDetails.planBerthRentInfo.unit :'-'}}</span>
            </p>
            <p>
              <span><em>租金保底价：</em>{{brandDetails.planBerthRentInfo.bottomPrice?brandDetails.planBerthRentInfo.bottomPrice + brandDetails.planBerthRentInfo.unit :'-'}}</span>
              <span><em>{{user.topuid === 6467 ? '物业' : '综合'}}管理费：</em>{{brandDetails.planBerthRentInfo.managementCost?brandDetails.planBerthRentInfo.managementCost + brandDetails.planBerthRentInfo.unit :'-'}}</span>
            </p>
            <p>
              <span><em>免租时长：</em>{{brandDetails.planBerthRentInfo.rentFreeTime?brandDetails.planBerthRentInfo.rentFreeTime:'-'}}</span>
              <span><em>招商品牌级次：</em>{{brandDetails.planBerthRentInfo.brandGradeType?brandDetails.planBerthRentInfo.brandGradeType:'-'}}</span>
            </p>
          </div>
        </div>
        <div
          class="berth-info"
          v-if="type === 2"
        >
          <div class="title">
            铺位招商信息 <i @click="toBerthInfo()">铺位招商管理</i>
          </div>
          <div class="berth-msg">
            <p>
              <span><em>招商负责人：</em>{{brandDetails.berthTaskInfo.principalName?brandDetails.berthTaskInfo.principalName:'-'}}</span>
              <span><em>铺位招商状态：</em>{{brandDetails.berthTaskInfo.taskState?brandDetails.berthTaskInfo.taskState: '-'}}</span>
            </p>
            <p>
              <span><em>意向落位品牌数：</em>{{brandDetails.berthTaskInfo.planAndIntentionBrandNum?brandDetails.berthTaskInfo.planAndIntentionBrandNum+'个': '-'}}</span>
              <span><em>跟进中品牌数：</em>{{brandDetails.berthTaskInfo.followBrandNum?brandDetails.berthTaskInfo.followBrandNum+'个':'-'}}</span>
            </p>
            <div>
              <span><em>铺位招商时效：</em>{{brandDetails.berthTaskInfo.berthTaskTime?brandDetails.berthTaskInfo.berthTaskTime: '-'}}</span>
            </div>
          </div>
        </div>
        <div class="drop-msg">
          <div
            class="title"
            v-if="brandDetails.signedBrands && brandDetails.signedBrands.length > 0"
          >合同信息预览
            <i
              class="fr ht-detail"
              @click="toContractDetail(brandDetails.signedBrands)"
            >合同详情</i>
          </div>
          <div
            class="signed-tab"
            v-if="brandDetails.signedBrands && brandDetails.signedBrands.length === 2"
          >
            <span
              v-for="(item, index) in brandDetails.signedBrands"
              :key="index"
              :class="[idx === index ? 'active' : '']"
              @click="tabClick(index)"
            >{{item.brand.brandName}}</span>
          </div>
          <div
            class="singed-brand-wrap"
            v-if="brandDetails.signedBrands && brandDetails.signedBrands.length > 0"
          >
            <div
              class="singed-brand-item"
              v-for="(item, index) in brandDetails.signedBrands"
              :key="index"
              v-show="index === idx"
            >
              <div
                class="drop-brand-info"
                @click="toDetails(item)"
              >
                <img
                  :src="item.brand.brandLogo?item.brand.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                  alt=""
                >
                <div class="name">
                  <div
                    class="brandName"
                    @click.stop="toDetails(item)"
                  >{{item.brand.brandName}}</div>
                  <div class="formatName">{{item.brand.industry}}</div>
                  <div
                    class="stateName"
                    :style="{background: item.task.color}"
                  >{{item.task.taskState}}</div>
                </div>
              </div>
              <div class="singed-info">
                <div class="singed-title">
                </div>
                <!-- <p><span>计租方式：{{item.rentWay === 1 ? '固租': (item.rentWay === 2 ? '扣点': '扣点+固租(取其高)')}}</span><span>租赁面积：{{item.rentArea ? item.rentArea+'m²': '-'}} </span></p>
                <p><span>签约租金：{{item.rentPrice ? item.rentPrice+'元/月/㎡': '-'}}</span><span v-if="item.rentWay !== 1">扣点比例：{{item.rentPercent?item.rentPercent+'%':'-'}}</span></p>
                <p>租赁周期：{{item.rentBeg ? item.rentBeg + '至' + item.rentEnd: '-'}}</p>
                <p>免租周期：{{item.freeBeg ? item.freeBeg + '至' + item.freeEnd: '-'}}</p>
                <p>租赁补充：{{item.rentRemark ? item.rentRemark : '-'}}</p> -->
                <p>合同号：{{item.contractCode ? item.contractCode : '-'}}</p>
                <p>合同有效日期：{{item.rentBeg ? item.rentBeg + '至' + item.rentEnd: '-'}}</p>
                <p>总合同面积：{{item.rentArea ? item.rentArea + 'm²' : '-'}}</p>
                <p>合同说明：{{item.rentRemark ? item.rentRemark : '-'}}</p>
              </div>
            </div>
          </div>
          <div class="title">
            {{type === 1 ? '落位品牌' : '意向落位品牌'}}
          </div>
          <div
            class="drop-brand-wrap"
            v-if="planBrands && planBrands.length > 0"
          >
            <div
              class="drop-brand-item"
              v-for="(item, index) in planBrands"
              :key="item.id"
            >
              <div class="header">
                <template v-if="item.isFirst === 0">备选落位品牌
                </template>
                <template v-if="item.isFirst === 1">首选落位品牌
                </template>
                <template v-if="item.isFirst === 2">意向品牌
                </template>
                <!-- {{item.isFirst === 1 ? '首选落位': '备选落位'}} -->
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  placement="bottom"
                >
                  <em>
                    <span
                      class="icon-mark"
                      v-if="index === 0"
                    ></span>
                  </em>
                  <div slot="content">当前方案分析品牌</div>
                </el-tooltip> -->
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="right"
                >
                  <em v-if="item.brand.brandLevelCompare || item.brand.industryCompare || item.brand.isFirstCompare">
                    <span class="icon-mark"></span>
                  </em>
                  <div
                    slot="content"
                    v-if="item.brand.industryCompare"
                  >{{item.brand.industryCompare}}</div>
                  <div
                    slot="content"
                    v-if="item.brand.brandLevelCompare"
                  >{{item.brand.brandLevelCompare}}</div>
                  <div
                    slot="content"
                    v-if="item.brand.isFirstCompare"
                  >{{item.brand.isFirstCompare}}</div>
                </el-tooltip>
                <span
                  class="edit-state"
                  v-if="item.task.taskState === '待招商' && $route.meta.title !== '落位计划管理'"
                  @click="releasePromotion(index, item)"
                >发布招商任务</span>
                <span
                  class="edit-state"
                  @click="editFollowStatus(item)"
                  v-if="(item.task.taskState === '待跟进' ||
                  item.task.taskState === '已联系' ||
                  item.task.taskState === '已看场' ||
                  item.task.taskState === '已预定' ||
                  item.task.taskState === '已报价') &&
                  item.approvalStatus !== '审批中' && type === 2"
                >
                  变更招商状态
                </span>
                <!-- <span
                  class="edit-state"
                  @click="editDropBrnad(brandDetails)"
                  v-if="type === 2 && (item.task.taskState === '已搁置' || item.task.taskState === '无意向')"
                >变更计划品牌</span> -->
              </div>
              <div
                class="drop-brand-info"
                @click="toInvesDetails(item)"
              >
                <i class="sort-num">{{index+1}}</i>
                <span
                  class="el-icon-close"
                  v-if="item.approvalStatus !== '审批中' && (type === 1 && item.isFirst !== 2)"
                  @click.stop="deletePlan(item)"
                ></span>
                <span
                  class="el-icon-close"
                  v-if="item.approvalStatus !== '审批中' && (type === 2 && item.isFirst === 2)"
                  @click.stop="deletePlan(item)"
                ></span>
                <img
                  :src="item.brand.brandLogo?item.brand.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                  alt=""
                >
                <div class="name">
                  <div
                    class="brandName"
                    @click.stop="toDetails(item)"
                  >{{item.brand.brandName}}</div>
                  <div class="formatName">{{item.brand.industry}}<span>{{item.brand.brandClass}}</span></div>
                  <div
                    class="stateName"
                    :style="{background: item.task.color}"
                  >{{item.task.taskState}}</div>
                </div>
              </div>
              <!-- <span :class="[item.task.approvalStatus === '审批中' ? 'approval-ing' : '']"></span> -->
              <!-- <span :class="[item.task.approvalStatus === '审批完成' ? 'approval-success' : '']"></span> -->
            </div>
          </div>
          <div
            class="details-no-data"
            v-if="planBrands && planBrands.length === 0"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>{{type === 1 ? '暂无落位品牌' : '暂无意向落位品牌'}}</p>
          </div>
        </div>
        <div
          v-if="type === 1"
          class="recommend-wrap"
          v-loading="loading"
        >
          <div class="title">
            {{type === 1 ? '落位品牌推荐' : '落位意向品牌推荐'}}
          </div>
          <div class="like-wrap">
            <ul>
              <li
                class="list-item"
                v-for="item in recommendBrandList"
                :key="item.brandId"
              >
                <div
                  class="brand-item-wrap"
                  @click.stop="toDetailsClick(item)"
                >
                  <div class="item-logo">
                    <img
                      :src="item.brandLogo?item.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                      alt=""
                    >
                    <span>{{item.brandName}}</span>
                  </div>
                  <p v-if="item.areaMin"><span>{{item.industry}}</span>{{item.areaMin?'/'+item.areaMin+'-':''}}{{item.areaMax?item.areaMax+'平米':''}}</p>
                  <p v-if="!item.areaMin">{{item.industry}}</p>
                  <p>已进驻{{item.shopNum}}家购物中心</p>
                </div>
                <div
                  class="item-btns"
                  @click="addRecommendBrand(item)"
                >{{type === 1 ? '添加落位品牌' : '添加意向品牌'}}</div>
              </li>
            </ul>
          </div>
          <div class="clearfix"></div>
          <div
            class="look-more-btn"
            @click="titleClick(2)"
          >
            查看更多推荐
          </div>
        </div>
      </div>
      <div
        class="details-btns"
        v-if="titleIdx === 1"
      >
        <span
          v-if="type === 1"
          class="plan-brand-btn"
          @click="editDropBrnad(brandDetails)"
        >计划品牌管理</span>
        <span
          v-if="type === 2"
          class="plan-brand-btn"
          @click="editDropBrnad(brandDetails)"
        >意向品牌管理</span>
        <span
          class="add-singed-btn"
          @click="addSinged()"
        >添加签约品牌</span>
      </div>
      <div
        class="intel-mask"
        v-if="showLoading"
      >
        <div class="ai-loading">
          <div class="ai-animation">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- <div class="tips">具有此项目入驻需求品牌</div> -->
          <div class="loading-text">品牌智能匹配中 ...</div>
        </div>
        <div class="project-info">
          <div class="info-title">项目匹配条件</div>
          <div class="info-details">
            <div class="details-top">
              <div class="top-item">
                <p class="val">{{dataDetails.projectName?dataDetails.projectName:'-'}}</p>
                <p class="text">匹配项目</p>
              </div>
              <div class="top-item">
                <p class="val">{{dataDetails.shangquanName?dataDetails.shangquanName:'-'}}</p>
                <p class="text">所属商圈</p>
              </div>
              <div class="top-item">
                <p class="val">{{dataDetails.floor?dataDetails.floor:'-'}}</p>
                <p class="text">落位楼层</p>
              </div>
            </div>
            <div class="details-bottom">
              <div class="bottom-item">
                <p class="val">{{dataDetails.useArea?dataDetails.useArea+'㎡':'-'}}</p>
                <p class="text">铺位面积</p>
              </div>
              <div class="bottom-item">
                <p class="val">{{dataDetails.industry?dataDetails.industry:'-'}}</p>
                <p class="text">铺位业态</p>
              </div>
              <div class="bottom-item">
                <p class="val">{{dataDetails.brandName?dataDetails.brandName:'-'}}</p>
                <p class="text">首选品牌</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="intel-wrap"
        v-show="titleIdx === 2"
      >
        <div class="brand-class">
          <div class="title">
            <span class="icon-brand-class"></span>
            品牌分类
          </div>
          <div class="radio-item-wrap">
            <el-radio-group
              v-model="industryId"
              @change="matchRadio"
            >
              <el-radio
                v-for="item in industryList"
                :key="item.id"
                :label="item.id"
              >{{item.item}}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="match-factor">
          <div class="title">
            <span class="icon-match-factor"></span>
            匹配因素
          </div>
          <div class="check-item-wrap">
            <el-checkbox-group
              v-model="matchList"
              @change="matchChange"
            >
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="1">铺位面积</el-checkbox>
                <div slot="content">开店面积与铺位面积相匹配的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                v-if="isBrandRecommend"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="2">品牌档次</el-checkbox>
                <div slot="content">品牌档次与首选落位品牌一致的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                v-if="isBrandRecommend"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="3">业态标签</el-checkbox>
                <div slot="content">业态标签与首选落位品牌有重合的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="5">楼层偏好</el-checkbox>
                <div slot="content">偏好本楼层大于30%的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="4">品牌进驻意向</el-checkbox>
                <div slot="content">品牌在当前项目拓展开店的意向</div>
              </el-tooltip>
            </el-checkbox-group>
            <div
              class="percentage"
              :class="[!isBrandRecommend ? 'positionA' : '']"
            >
              <span>{{rankText}}</span>
              <div class="ranks">
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="top"
                >
                  <a
                    class="first"
                    @click="ranksClick(1)"
                    :class="[ranksIdx === 1 ? 'active' : '']"
                    href="javascript:;"
                  >
                    <span class="bg"></span>
                    <span
                      class="bar"
                      style="height: 15px;"
                    ></span>
                  </a>
                  <div slot="content">进驻概率大于60%的品牌</div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="top"
                >
                  <a
                    @click="ranksClick(2)"
                    :class="[ranksIdx === 2 ? 'active' : '']"
                    href="javascript:;"
                  >
                    <span class="bg"></span>
                    <span
                      class="bar"
                      style="height: 11px;"
                    ></span>
                  </a>
                  <div slot="content">进驻概率介于30%到60%的品牌</div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="top"
                >
                  <a
                    @click="ranksClick(3)"
                    :class="[ranksIdx === 3 ? 'active' : '']"
                    href="javascript:;"
                  >
                    <span class="bg"></span>
                    <span
                      class="bar"
                      style="height: 6px;"
                    ></span>
                  </a>
                  <div slot="content">进驻概率小于30%的品牌</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="sort-condition-wrap">
          <div class="title">
            <span class="icon-condition"></span>
            排序条件
            <i
              class="icon-mark"
              @click="tipsHide=true"
            ></i>
          </div>
          <div class="sort-item">
            <span
              @click="sortClick('defultScore')"
              :class="[sortBy === 'defultScore' ? 'active': '']"
            >默认排序</span><span
              @click="sortClick('HotScore')"
              :class="[sortBy === 'HotScore' ? 'active': '']"
            >按人气指数排序</span><span
              @click="sortClick('PotentialScore')"
              :class="[sortBy === 'PotentialScore' ? 'active': '']"
            >按潜力指数排序</span><span
              @click="sortClick('OperatorScore')"
              :class="[sortBy === 'OperatorScore' ? 'active': '']"
            >按租金贡献指数</span>
          </div>
        </div>
        <div class="sort-tips">
          智能品牌匹配说明：基于赢商大数据4w+的品牌开店拓展需求及指数数据等，同时结合项目
          周边及铺位条件，智能匹配相似度比较高的品牌以及品牌方入驻需求与本项目高契合度的品牌 (注：由于数据采集时间周期关系，品牌开店数据可能存在部分误差)
        </div>
        <div class="like-wrap">
          <ul>
            <li
              class="list-item"
              v-for="item in brandList"
              :key="item.id"
            >
              <div
                class="brand-item-wrap"
                @click.stop="toDetailsClick(item)"
              >
                <div class="item-logo">
                  <img
                    :src="item.brandLogo?item.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                    alt=""
                  >
                  <span>{{item.brandName}}</span>
                </div>
                <p v-if="item.areaMin"><span>{{item.industry}}</span>{{item.areaMin?'/'+item.areaMin+'-':''}}{{item.areaMax?item.areaMax+'平米':''}}</p>
                <p v-if="!item.areaMin">{{item.industry}}</p>
                <p
                  class="ellipsis"
                  v-if="item.shopNum"
                >{{'已进驻' + item.shopNum + '家购物中心'}}{{item.shopNum && item.chainMarketNum ? '+': ''}}{{item.chainMarketNum>0?item.chainMarketNum + '家百货':''}}</p>
                <p
                  class="ellipsis"
                  v-else
                >{{'已进驻' + item.shopNum + '家购物中心'}}{{item.shopNum && item.chainMarketNum ? '+': ''}}{{item.chainMarketNum>0?'已进驻' + item.chainMarketNum + '家百货':''}}</p>
              </div>
              <div
                class="item-btns"
                @click="addRecommendBrand(item)"
              >添加落位品牌</div>
            </li>
          </ul>
        </div>
        <div
          class="details-no-data"
          v-if="brandList && brandList.length === 0"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂无推荐品牌</p>
        </div>
      </div>
      <!-- 中止签约 -->
      <div
        class="follow-wrap"
        v-if="isStopSinged"
      >
        <div class="edit-follow-wrap">
          <i
            @click="hideStopSinged"
            class="el-icon-close"
          ></i>
          <h3>中止签约</h3>
          <div class="brand-details1">
            <div
              class="brand-head"
              style="border-bottom:1px solid rgba(234,210,183,.5);"
            >
              <span style="width: 35%">品牌名称</span>
              <span style="width: 30%">签约楼层铺位</span>
              <span style="width: 30%">租赁周期</span>
            </div>
            <div class="brand-body">
              <span style="width: 35%">{{stopSingedBrandDetails.brand.brandName}}</span>
              <span style="width: 30%">{{brandDetails.berth.floor}}/{{brandDetails.berth.berthNo}}</span>
              <span style="width: 30%">{{stopSingedBrandDetails.rentBeg ? stopSingedBrandDetails.rentBeg + '-' + stopSingedBrandDetails.rentEnd: '-'}}</span>
            </div>
          </div>
          <div class="remarksmk">
            失效时间：
            <el-date-picker
              v-model="stopTime"
              type="date"
              placeholder="请选择签约中止日期"
            >
            </el-date-picker>
          </div>
          <div class="stop-brand-tips"><span class="icon-msg"></span> 说明：失效时间将被用来计算租赁周期内的租金收益，所以请录入准确的签约中止时间</div>
          <div class="btn-wrap">
            <span
              @click="hideStopSinged"
              class="cancal"
            >取消</span>
            <span @click="determineClick">确定</span>
          </div>
        </div>
      </div>
      <brand-index
        v-if="tipsHide"
        @closeBrandIndex="closeBrandIndex"
      ></brand-index>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import brandIndex from '@/components/brandIndexTips.vue'
import { powerAuth } from '@/utils'
export default {
  data () {
    return {
      loading: true,
      showLoading: true,
      isStopSinged: false,
      stateIdex: null,
      stopSingedBrandDetails: {},
      stopTime: '',
      titleIdx: 1,
      matchList: [],
      tipsHide: false,
      sortBy: 'defultScore',
      ranksIdx: null,
      rankText: '',
      isBrandRecommend: false,
      industryId: '0',
      industryList: [],
      brandList: [],
      recommendBrandList: [],
      dataDetails: {},
      modulesList: [],
      idx: 0,
      investmentShow: false,
      invesmtentItem: {},
      planBrands: []
    }
  },
  props: {
    brandDetails: {
      type: Object
    },
    projectId: {
      type: Number
    },
    type: {
      type: Number
    }
  },
  components: {
    brandIndex
  },
  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user'
    })
  },
  created () {
    localStorage.setItem('isMatch', 'false')
  },
  beforeDestroy () {
    window.removeEventListener('click', this.closeClick)
  },
  mounted () {
    if (this.type === 1) {
      this.planBrands = this.brandDetails.planBrands.filter(item => {
        return item.isFirst !== 2
      })
    } else if (this.type === 2) {
      this.planBrands = this.brandDetails.planBrands
    }
    if (this.brandDetails && this.brandDetails.planBrands.length === 0) {
      this.axios.post(api.recommendBerth, {
        areaMatch: 1,
        berthId: this.brandDetails.berth.berthId,
        projectId: this.projectId,
        industryId: '0',
        sortBy: 'defultScore',
        duelMatch: 0,
        duelScreen: 0
      })
        .then((res) => {
          if (res.data.data) {
            this.recommendBrandList = res.data.data.recommendList.splice(0, 6)
          }
          this.loading = false
        })
    } else {
      this.axios.post(api.recommendBrand, {
        areaMatch: 1,
        brandGradeMatch: 0,
        tagnameMatch: 0,
        brandId: this.brandDetails.planBrands[0].brandId,
        gbid: this.brandDetails.planBrands[0].gbid,
        berthId: this.brandDetails.berth.berthId,
        projectId: this.projectId,
        industryId: '0',
        sortBy: 'defultScore',
        duelMatch: 0,
        duelScreen: 0
      })
        .then((res) => {
          if (res.data.data) {
            this.recommendBrandList = res.data.data.recommendList.splice(0, 6)
          }
          this.loading = false
        })
    }
    this.getModularListFunc()
    // 点击元素之外隐藏
    window.addEventListener('click', this.closeClick)
  },
  methods: {
    toRentPlanEdit () {
      if (!powerAuth(212, 'edit')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.$emit('toRentPlanEdit', this.brandDetails.berth.berthId)
    },
    editFollowStatus (item) {
      this.$emit('editBrandStatusEmit', item)
    },
    hideInvestmentEmit (type) {
      if (type) {
        this.getInvestmentList()
      }
      this.investmentShow = false
    },
    tabClick (index) {
      this.idx = index
    },
    closeBrandIndex () {
      this.tipsHide = false
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 11
          })
        })
    },
    closeClick (e) {
      if (e.target.className === 'brand-details') {
        this.hideMapDetails()
      }
    },
    addRecommendBrand (item) {
      this.checkEnterpriseTabelBrandDataFunc(item).then((res) => {
        if (res.data > 0) {
          item.gbid = res.data
          this.$emit('selectRecommendEmit', item)
        } else {
          this.$confirm('该品牌将同步到企业品牌库，是否确认？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            showClose: false,
            customClass: 'invitePosition'
          }).then((res) => {
            const exportJurisdiction = this.user.confIds.filter(item => {
              return item.id === 180
            })
            if (exportJurisdiction.length === 0) {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
              return false
            }
            this.axios.post(api.exportBrandToEnterpriseBrandTableSingular, {
              brandId: item.brandId
            })
              .then((res) => {
                if (res.data.code === 0) {
                  item.gbid = res.data.data
                  this.$emit('selectRecommendEmit', item)
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg
                  })
                }
              })
          })
        }
      })
    },
    checkEnterpriseTabelBrandDataFunc (item) {
      return new Promise((resolve) => {
        this.axios.post(api.checkEnterpriseTabelBrandData, {
          brand_id: item.brandId,
          brandName: item.brandName
        })
          .then((res) => {
            resolve(res.data)
          })
      })
    },
    titleClick (idx) {
      this.titleIdx = idx
      if (idx === 2) {
        if (localStorage.getItem('isMatch') === 'false') {
          this.getMatchProjectDetailsFunc()
          if (this.brandDetails && this.brandDetails.planBrands.length === 0) {
            this.isBrandRecommend = false
            setTimeout(() => {
              this.getBerthList()
            }, 3000)
          } else {
            this.isBrandRecommend = true
            setTimeout(() => {
              this.getBrandList()
            }, 3000)
          }
        }
      }
    },
    getMatchProjectDetailsFunc () {
      this.axios.post(api.getMatchProjectDetails, {
        berthId: this.brandDetails.berth.berthId
      })
        .then((res) => {
          this.dataDetails = res.data.data
        })
    },
    matchRadio () {
      this.showLoading = true
      if (this.brandDetails && this.brandDetails.planBrands.length === 0) {
        this.isBrandRecommend = false
        this.getBerthList()
      } else {
        this.isBrandRecommend = true
        this.getBrandList()
      }
    },
    matchChange () {
      this.showLoading = true
      if (this.matchList.indexOf('4') > -1) {
        this.rankText = '较强'
        this.ranksIdx = 1
      } else {
        this.rankText = ''
        this.ranksIdx = null
      }
      if (this.brandDetails && this.brandDetails.planBrands.length === 0) {
        this.isBrandRecommend = false
        this.getBerthList()
      } else {
        this.isBrandRecommend = true
        this.getBrandList()
      }
    },
    ranksClick (idx) {
      this.showLoading = true
      if (this.matchList.indexOf('4') === -1) {
        this.matchList.push('4')
      }
      this.ranksIdx = idx
      this.rankText = idx === 1 ? '较强' : (idx === 2 ? '一般' : '较弱')
      if (this.brandDetails && this.brandDetails.planBrands.length === 0) {
        this.isBrandRecommend = false
        this.getBerthList()
      } else {
        this.isBrandRecommend = true
        this.getBrandList()
      }
    },
    sortClick (idx) {
      this.showLoading = true
      this.sortBy = idx
      if (this.brandDetails && this.brandDetails.planBrands.length === 0) {
        this.isBrandRecommend = false
        this.getBerthList()
      } else {
        this.isBrandRecommend = true
        this.getBrandList()
      }
    },
    getBerthList () {
      this.axios.post(api.recommendBerth, {
        areaMatch: this.matchList.indexOf('1') === -1 ? 0 : 1,
        floorMatch: this.matchList.indexOf('5') === -1 ? 0 : 1,
        berthId: this.brandDetails.berth.berthId,
        projectId: this.projectId,
        industryId: this.industryId,
        sortBy: this.sortBy,
        duelMatch: this.matchList.indexOf('4') === -1 ? 0 : 1,
        duelScreen: this.ranksIdx > 0 ? this.ranksIdx : 0
      })
        .then((res) => {
          this.industryList = res.data.data.industryList
          this.brandList = res.data.data.recommendList
          this.showLoading = false
          localStorage.setItem('isMatch', 'true')
        })
    },
    getBrandList () {
      this.axios.post(api.recommendBrand, {
        areaMatch: this.matchList.indexOf('1') === -1 ? 0 : 1,
        brandGradeMatch: this.matchList.indexOf('2') === -1 ? 0 : 1,
        tagnameMatch: this.matchList.indexOf('3') === -1 ? 0 : 1,
        floorMatch: this.matchList.indexOf('5') === -1 ? 0 : 1,
        brandId: this.brandDetails.planBrands[0].brandId,
        gbid: this.brandDetails.planBrands[0].gbid,
        berthId: this.brandDetails.berth.berthId,
        projectId: this.projectId,
        industryId: this.industryId,
        sortBy: this.sortBy,
        duelMatch: this.matchList.indexOf('4') === -1 ? 0 : 1,
        duelScreen: this.ranksIdx > 0 ? this.ranksIdx : 0
      })
        .then((res) => {
          this.industryList = res.data.data.industryList
          this.brandList = res.data.data.recommendList
          this.showLoading = false
          localStorage.setItem('isMatch', 'true')
        })
    },
    releasePromotion (index, item) {
      console.log(item)
      const arr = []
      const obj = {}
      // obj = item
      obj.label = item.berthNo + ' ' + item.brand.brandName + (item.isFirst === 1 ? ' ( 首选落位 )' : '')
      obj.value = item.brand.brandName
      obj.floor = item.berthNo
      obj.level = 3
      obj.id1 = 'A座' + item.floor
      obj.id2 = item.berthNo
      obj.id = item.berthId + item.gbid
      obj.followName = ''
      obj.followId = ''
      obj.timeVal = []
      obj.planBrands = []
      obj.industry = item.brand.industry
      obj.brandLogo = item.brand.brandLogo
      obj.brandName = item.brand.brandName
      obj.typeNames = item.brand.industry
      obj.brandClass = item.brand.brandClass
      obj.gbid = item.brand.gbid
      obj.isOpen = false
      // obj.disabled = item.approvalStatus === '审批中' ? true : undefined
      arr.push(obj)
      // this.$store.dispatch('setInvestmentBrandList', arr)
      this.$emit('toInvestmentEmit', 1, index, arr)
    },
    deleteSinged (item) {
      this.$emit('deleteSingedEmit', item)
    },
    deletePlan (item) {
      if (this.type === 1) {
        if (!powerAuth(213, 'delplanbrand')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      }
      const params = {
        taskId: item.task.id
      }
      this.axios.post(api.getTaskApprovalStatus, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData === '审批中') {
            this.$message({
              type: 'warning',
              message: '当前招商任务存在未完结的审批单，无法操作'
            })
          } else {
            this.$emit('deletePlanEmit', item)
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    addSinged () {
      this.$emit('addSingedEmit')
    },
    editSingedBrand (index, item) {
      this.$emit('editSingedBrandEmit', index)
    },
    toContractDetail (item) {
      this.$emit('toContractDetailEmit', item)
    },
    addDropBrand () {
      this.$emit('addDropBrandEmit')
    },
    // 计划品牌管理
    editDropBrnad (data) {
      if (this.type === 1) {
        if (!powerAuth(213, 'addplanbrand')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      } else {
        if (!powerAuth(216, 'editintentionbrand')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      }
      this.$emit('editDropBrandEmit', data)
    },
    followItem (item, state) {
      const params = {
        taskId: item.task.id
      }
      this.axios.post(api.getTaskApprovalStatus, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData === '审批中') {
            this.$message({
              type: 'warning',
              message: '当前招商任务存在未完结的审批单，无法操作'
            })
          } else {
            this.$emit('changeBrandStateEmit', item, state)
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    mouseState (index) {
      this.stateIdex = index
    },
    mousetoutState (e) {
      this.stateIdex = null
    },
    hideStopSinged () {
      this.isStopSinged = false
    },
    hideMapDetails () {
      localStorage.setItem('isMatch', 'true')
      this.$emit('hideMapDetails')
    },
    determineClick () {
      this.$confirm('确认要将该品牌签约设置为失效状态，设置失效后该签约信息将不可再更改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'invitePosition'
      }).then((res) => {
        this.axios.post(api.setInvalidBrand, { id: this.stopSingedBrandDetails.id, invalidTime: this.stopTime })
          .then((res) => {
            this.$message({
              type: 'success',
              message: '设置成功！'
            })
            this.isStopSinged = false
            this.$emit('stopBrandEmit')
          })
      })
    },
    stopSingedBrand (item) {
      this.stopSingedBrandDetails = item
      this.isStopSinged = true
    },
    toBerthDetails (item) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/unit/details?menuCode=11&unitId=' + this.brandDetails.berth.berthId + '&tokenval=' + this.token, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    toDetails (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brand.brandId + '&gbid=' + item.brand.gbid + '&brandName=' + item.brand.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    toDetailsClick (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    toBerthInfo () {
      if (!powerAuth(216)) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      const brandDetailsHref = this.$router.resolve({
        path: '/berthInvestmentManageDetail',
        query: {
          id: this.brandDetails.berth.berthId
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    toInvesDetails (item) {
      if (item.task.taskState === '待招商') {
        const userAuthorities = this.user.confIds.filter(item => {
          return item.id === 164
        })
        if (userAuthorities.length > 0) {
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brand.brandId + '&gbid=' + item.brand.gbid + '&brandName=' + item.brand.brandName, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，您暂无查看企业品牌详情权限！'
          })
        }
      } else if (item.task.taskState !== '已预定' || item.task.taskState !== '已签约') {
        const singleBrandHref = this.$router.resolve({
          path: '/investmentFollowDetail',
          query: {
            id: item.task.id
          }
        })
        window.open('/' + singleBrandHref.href, '_blank')
      }
    },
    toBerthEdit () {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/unit/details?menuCode=11&unitId=' + this.brandDetails.berth.berthId + '&tokenval=' + this.token, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
// .brand-details
// height 100vh
// .map-details
// width 500px
// height 100%
// background rgba(39, 41, 48, 1)
// box-shadow 0px 0px 14px 0px rgba(30, 30, 35, 1)
.brand-details
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.4)
  z-index 1111
  .map-details
    position absolute
    right 0
    width 500px
    height 100%
    background rgba(39, 41, 48, 1)
    box-shadow 0px 0px 14px 0px rgba(30, 30, 35, 1)
  .details-title
    position relative
    height 70px
    line-height 70px
    background #32343B
    color #808191
    font-size 16px
    font-weight 500
    span
      font-size 16px
      margin 0 23px
      cursor pointer
      padding-bottom 20px
      &.active
        border-bottom 3px solid #ffa133
        color #fff
    .el-icon-close
      position absolute
      right 20px
      top 25px
      cursor pointer
  .details-content
    padding 24px
    padding-top 40px
    margin-bottom 120px
    height calc(100% - 190px)
    overflow-y auto
    .berth-info
      margin-bottom 48px
      .title
        position relative
        color #fff
        padding-left 8px
        font-size 16px
        &:after
          content ''
          display inline-block
          position absolute
          left 0
          top 6px
          width 2px
          height 14px
          background #F89407
          z-index 11
        span
          position absolute
          left 0
          top 2px
          width 2px
          height 16px
          color #fff
          font-size 20px
        i
          position absolute
          right 0
          top 3px
          font-size 14px
          color #ffa134
          cursor pointer
      .berth-msg
        margin-top 24px
        color #fff
        font-size 14px
        font-weight 400
        padding 0 8px
        p
          margin-bottom 17px
          span:first-child
            display inline-block
            width 45%
            em
              color #B2B3BD
          span:last-child
            display inline-block
            width 55%
            em
              color #B2B3BD
        >div
          color #fff
          em
            color #B2B3BD
    .drop-msg
      margin-top 48px
      .title
        position relative
        color #fff
        padding-left 8px
        font-size 16px
        &:after
          content ''
          display inline-block
          position absolute
          left 0
          top 6px
          width 2px
          height 14px
          background #F89407
          z-index 11
        span
          position absolute
          left 0
          top 2px
          width 2px
          height 16px
          color #fff
          font-size 20px
      .drop-brand-wrap
        .drop-brand-item
          margin-top 24px
          position relative
          padding-left 8px
          .header
            position relative
            color #ffffff
            font-size 14px
            .icon-mark:before
              font-size 14px
            .edit-state
              position relative
              color #ffa134
              float right
              margin-top 4px
              cursor pointer
          .drop-brand-info
            position relative
            width 100%
            height 77px
            background #1E1E23
            margin-top 14px
            display flex
            align-items center
            cursor pointer
            .el-icon-close
              position absolute
              right 10px
              top 10px
              cursor pointer
              color #d9d9d9
              font-size 12px
            .sort-num
              position absolute
              left 10px
              top 10px
              width 18px
              height 18px
              background rgba(255, 161, 52, 0.8)
              color #ffffff
              line-height 18px
              text-align center
            img
              width 77px
              height 57px
              margin-left 10px
              margin-right 10px
              object-fit cover
              border-radius 2px
            .name
              .brandName
                font-size 14px
                color #fff
                font-weight 500
                text-decoration underline
                &:hover
                  color #F89407
              .formatName
                font-size 12px
                color #808191
                font-weight 500
                margin-top 3px
                margin-bottom 5px
                span
                  margin-left 10px
              .stateName
                width 64px
                height 16px
                border-radius 2px
                color #fff
                text-align center
                line-height 16px
                font-size 12px
                font-weight 500
                span
                  display inline-block
                  width 6px
                  height 6px
                  border-radius 50%
                  background #000
                  vertical-align middle
      .singed-brand-wrap
        margin-top 16px
        .singed-brand-item
          margin-bottom 32px
          padding-left 8px
          .drop-brand-info
            position relative
            width 100%
            height 77px
            background #1E1E23
            margin-top 15px
            display flex
            align-items center
            cursor pointer
            .el-icon-close
              position absolute
              right 10px
              top 10px
              cursor pointer
              color #d9d9d9
              font-size 12px
            img
              width 77px
              height 57px
              margin-left 10px
              margin-right 10px
              object-fit cover
              border-radius 2px
            .name
              .brandName
                font-size 14px
                color #fff
                font-weight 500
                text-decoration underline
                &:hover
                  color #F89407
              .formatName
                font-size 12px
                color #808191
                font-weight 500
                margin-top 3px
                margin-bottom 5px
              .stateName
                background #10CC61
                width 64px
                height 16px
                border-radius 2px
                color #fff
                text-align center
                line-height 16px
                font-size 12px
                font-weight 500
                span
                  display inline-block
                  width 6px
                  height 6px
                  border-radius 50%
                  background #1A7C44
        .singed-info
          margin-top 24px
          .singed-title
            color #ffffff
            font-size 14px
            margin-bottom 24px
            position relative
            .singed-icons
              position absolute
              right 0
              top 0
              width 120px
            span
              color #FFA134
              float right
              font-size 12px
              margin-top 3px
              cursor pointer
              margin-left 10px
            .icon-edit2:before
              color #ffa134
            .icon-del:before
              color #ffa134
          p
            color #fff
            font-size 14px
            margin-bottom 22px
            span
              &:first-child
                display inline-block
                width 60%
  .details-btns
    position absolute
    bottom 0
    width 100%
    height 76px
    background rgba(39, 41, 48, 1)
    // box-shadow 0px 0px 14px 0px rgba(30, 30, 35, 1)
    display flex
    justify-content center
    z-index 111
    border-top 1px solid #373A43
    padding-top 20px
    box-sizing border-box
    .plan-brand-btn
      display inline-block
      width 164px
      height 36px
      line-height 36px
      border-radius 4px
      background #FFA134
      color #ffffff
      font-size 16px
      text-align center
      margin-right 12px
      cursor pointer
    .add-singed-btn
      display inline-block
      width 162px
      height 34px
      line-height 36px
      border-radius 4px
      border 1px solid #FFA134
      color #FFA134
      font-size 16px
      text-align center
      margin-left 12px
      cursor pointer
.details-no-data
  margin-top 70px
  text-align center
  p
    color #fff
    font-size 12px
    margin-top 10px
.states-wrap
  position absolute
  right 10px !important
  top 25px
  width 75px
  background rgba(39, 41, 48, 1)
  box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
  z-index 111
  text-align center
  color #ffffff
  p
    height 26px
    line-height 26px
    &:hover
      color #FFA134
.follow-wrap
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 0.5)
  z-index 111
  .edit-follow-wrap
    position absolute
    left 50%
    margin-left -360px
    top 150px
    width 720px
    min-height 410px
    background #ffffff
    border-radius 6px
    padding 30px 20px
    box-sizing border-box
    .el-icon-close
      position absolute
      right 20px
      top 15px
      color #D9D9D9
      cursor pointer
    h3
      font-size 18px
      color #333
      font-weight bold
  .brand-details1
    border 1px solid rgba(255, 161, 52, 1)
    box-shadow 0px 5px 8px 0px rgba(50, 29, 6, 0.04)
    padding 10px
    margin-top 30px
    .brand-head
      height 32px
      line-height 32px
      span
        display inline-block
        color #999
        font-size 14px
        font-weight 500
    .brand-body
      height 32px
      line-height 32px
      span
        display inline-block
        color #333
        font-size 14px
        font-weight 500
  .remarksmk
    margin-top 20px
    color #333
    margin-top 40px
  .btn-wrap
    text-align center
    margin-top 30px
    span
      display inline-block
      width 200px
      height 40px
      line-height 40px
      text-align center
      color #fff
      background #FFA134
      font-size 16px
      font-weight 400
      border-radius 4px
      cursor pointer
      border 1px solid #FFA134
      &.cancal
        background #ffffff
        color #FFA134
        border 1px solid #FFA134
        margin-right 20px
.stop-brand-tips
  margin-top 30px
  width 100%
  background #FBF7F2
  height 32px
  line-height 32px
  color #DF9B4E
  font-size 12px
  border-radius 2px
  .icon-msg:before
    font-size 16px
    margin-left 8px
    margin-right 5px
.brandIndex-tips
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  z-index 111
  .tips-box
    position fixed
    top 20%
    left 50%
    margin-left -325px
    width 650px
    padding 20px 32px 40px 32px
    background #fff
    h3
      text-align center
      color #333
      font-size 28px
    .content
      margin-top 25px
      .title
        color #333
        font-size 14px
        margin-top 6px
        margin-bottom 6px
      .text
        color #666
        font-size 14px
        line-height 22px
    .el-icon-close
      cursor pointer
.recommend-wrap
  margin-top 48px
  .title
    position relative
    color #fff
    padding-left 8px
    font-size 16px
    &:after
      content ''
      display inline-block
      position absolute
      left 0
      top 6px
      width 2px
      height 14px
      background #F89407
      z-index 11
    span
      position absolute
      left 0
      top 2px
      width 2px
      height 16px
      color #fff
      font-size 20px
  .look-more-btn
    display inline-block
    width 100%
    height 36px
    line-height 36px
    border-radius 4px
    // border 1px solid #ffa134
    color #fff
    font-size 14px
    text-align center
    cursor pointer
    margin-top 24px
  .like-wrap
    margin-top 32px
    padding-left 8px
    ul li
      position relative
      float left
      width 48.5%
      height 172px
      // background #fff
      padding 0px 10px 10px 0
      box-shadow 0px 9px 32px 0px rgba(50, 29, 6, 0.06)
      box-sizing border-box
      margin-bottom 12px
      margin-right 10px
      .brand-item-wrap
        cursor pointer
      &:nth-child(2n)
        margin-right 0
      .item-logo
        display flex
        align-items center
        margin-bottom 12px
        img
          width 77px
          height 57px
          object-fit cover
          border-radius 2px
        span
          font-size 14px
          font-weight 500
          margin-left 10px
          text-overflow ellipsis
          display -webkit-box
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          overflow hidden
          text-decoration underline
          color #fff
          &:hover
            color #f89407
      p
        font-size 14px
        line-height 18px
        font-weight 500
        color #808191
      .item-btns
        position absolute
        bottom 10px
        width 174px
        height 32px
        line-height 32px
        background #373A43
        border-radius 4px
        text-align center
        color #fff
        font-size 14px
        cursor pointer
        &:hover
          background #f89407
          color #fff
.intel-wrap
  padding 20px
  height calc(100% - 110px)
  overflow-y auto
  margin-bottom 40px
  .brand-class
    .title
      position relative
      color #fff
      padding-left 28px
      font-size 16px
      span
        position absolute
        left 0
        top 2px
        width 2px
        height 16px
        color #fff
        font-size 20px
    .radio-item-wrap
      margin-top 20px
  .match-factor
    margin-top 30px
    .title
      position relative
      color #fff
      padding-left 28px
      font-size 16px
      span
        position absolute
        left 0
        top 2px
        width 2px
        height 16px
        color #fff
        font-size 20px
  .check-item-wrap
    position relative
    margin-top 20px
    .positionA
      position absolute
      left 128px !important
      top 33px !important
    .percentage
      position absolute
      left 265px
      top 33px
      &>span
        float left
        width 38px
        height 20px
        font-size 12px
        line-height 20px
        text-align center
        border 1px solid rgba(149, 150, 151, 0.5)
        border-radius 2px
        color #fff
      .ranks
        float left
        margin-left 10px
        .first
          width 16px
          .bg
            border-left 1px solid rgba(149, 150, 151, 1)
          .bar
            left 0
        .active
          .bar
            background #ffa134
        a
          position relative
          float left
          height 20px
          width 15px
          &:hover
            .bar
              background #ffa134
          .bg
            position absolute
            top 0
            left 0
            height 20px
            width 14px
            border 1px solid rgba(149, 150, 151, 1)
            border-left none
            background #272930
          .bar
            position absolute
            left -1px
            bottom -2px
            width 15px
            border-right 1px solid rgba(149, 150, 151, 1)
            background rgba(255, 255, 255, 0.2)
  .sort-condition-wrap
    margin-top 20px
    .title
      position relative
      color #fff
      padding-left 28px
      font-size 16px
      span
        position absolute
        left 0
        top 2px
        width 2px
        height 16px
        color #fff
        font-size 20px
      i
        margin-left 10px
        cursor pointer
    .sort-item
      border 1px solid rgba(255, 255, 255, 0.1)
      border-radius 3px
      // min-height 40px
      // line-height 40px
      padding 10px 0
      margin-top 20px
      span
        font-size 12px
        color #999999
        cursor pointer
        border-right 1px solid rgba(255, 255, 255, 0.1)
        // padding 5px 9px 5px 11px
        display inline-block
        width 24.5%
        text-align center
        &:last-child
          border none
        &.active
          color #ffa134
  .sort-tips
    padding 10px
    font-size 12px
    color #fff
    line-height 16px
    font-weight 500
    margin-top 20px
    background rgba(255, 255, 255, 0.05)
  .like-wrap
    margin-top 20px
    ul li
      position relative
      float left
      width 48.5%
      height 172px
      // background #fff
      padding 10px
      box-shadow 0px 9px 32px 0px rgba(50, 29, 6, 0.06)
      box-sizing border-box
      margin-bottom 12px
      margin-right 10px
      .brand-item-wrap
        cursor pointer
      &:nth-child(2n)
        margin-right 0
      .item-logo
        display flex
        align-items center
        margin-bottom 15px
        img
          width 77px
          height 57px
          object-fit cover
        span
          font-size 14px
          font-weight 500
          margin-left 10px
          text-overflow ellipsis
          display -webkit-box
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          overflow hidden
          text-decoration underline
          color #fff
      p
        font-size 14px
        line-height 18px
        font-weight 500
        color #808191
      .item-btns
        position absolute
        bottom 10px
        width 174px
        height 32px
        line-height 32px
        background #373A43
        border-radius 4px
        text-align center
        color #fff
        font-size 14px
        cursor pointer
        &:hover
          background #f89407
          color #fff
.intel-mask
  height calc(100% - 70px)
  .ai-loading
    width 456px
    border-radius 4px
    z-index 1111
    text-align center
    .ai-animation
      margin-top 180px
      span
        display inline-block
        width 20px
        height 20px
        border-radius 2px
        margin 0 5px
        background #FFA134
        animation 5s morph linear infinite
    .tips
      color #ffffff
      font-size 14px
      margin-top 8px
      margin-bottom 5px
    .loading-text
      color #ffffff
      font-size 16px
      font-weight bold
      margin-top 8px
  .item-li
    min-height 50px
  .project-info
    width 405px
    height 255px
    margin auto
    margin-top 70px
    background url('~@/assets/images/workBench/intel-bg.png') no-repeat
    background-size 100%
    .info-title
      font-size 16px
      color #FFA134
      font-weight bold
      text-align center
      padding-top 30px
    .info-details
      margin-top 28px
      padding 0 16px
      .details-top
        display flex
        border-bottom 1px solid rgba(255, 161, 52, 0.1)
        align-items center
        .top-item
          width 33%
          text-align center
          border-right 1px solid rgba(255, 161, 52, 0.1)
          padding 20px 0
          &:last-child
            border none
          .val
            font-size 14px
            color #fff
            font-weight 400
            margin-bottom 7px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
          .text
            font-size 12px
            color rgba(255, 255, 255, 0.5)
            font-weight 400
      .details-bottom
        display flex
        align-items center
        .bottom-item
          width 33%
          text-align center
          border-right 1px solid rgba(255, 161, 52, 0.1)
          padding 20px 0
          &:last-child
            border none
          .val
            font-size 14px
            color #fff
            font-weight 400
            margin-bottom 7px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
          .text
            font-size 12px
            color rgba(255, 255, 255, 0.5)
            font-weight 400
@keyframes morph
  0%
    border-radius 25%
    transform rotate(0deg)
  25%
    border-radius 50%
    transform rotate(90deg)
  50%
    border-radius 50%
    transform rotate(90deg)
  75%
    border-radius 25%
    transform rotate(45deg)
  100%
    border-radius 5%
    transform rotate(0deg)
.ht-detail
  color #ffa134
  font-size 14px
  cursor pointer
.approval-success
  position absolute
  bottom 0
  right 0
  display inline-block
  width 56px
  height 36px
  background url('~@/assets/images/approval-success.png') no-repeat
  background-size 100%
  z-index 11
.approval-ing
  position absolute
  bottom 0
  right 0
  display inline-block
  width 56px
  height 36px
  background url('~@/assets/images/approval-ing.png') no-repeat
  background-size 100%
  z-index 11
.signed-tab
  margin-top 16px
  span
    color #fff
    font-size 14px
    padding-bottom 5px
    margin-right 14px
    cursor pointer
    &.active
      color #ffa134
      border-bottom 1px solid #ffa134
</style>
