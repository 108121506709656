<template>
  <div class="map-config">
    <div class="map-configure-wrap">
      <div class="map-configure">
        <h3>落位图显示数据配置<i
            class="el-icon-close"
            @click="hideConfigFunc"
          ></i></h3>
        <div class="configure-content">
          <div
            class="content-item"
            v-for="(item, index) in mapConfigData"
            :key="index"
          >
            <div class="info-title">{{item.plableName}}
              <span v-if="item.plableName === '招商中铺位'">(未签约且在招商中)</span>
              <span v-if="item.plableName === '继续招商中铺位'">(已签约且仍在招商中)</span>
              <span v-if="item.plableName === '已签约铺位'">(已签约且停止招商)</span>
            </div>
            <div class="info-check">
              <el-checkbox-group v-model="item.checkIds">
                <el-checkbox
                  v-for="item in item.lableList"
                  :key="item.id"
                  :label="item.id"
                  :disabled="item.name === '铺位号'"
                >{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        class="sure"
        @click="updateMapFunc()"
      >确定</span>
      <span
        class="cancal"
        @click="hideConfigFunc"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  props: {
    mapConfigData: {
      type: Array
    }
  },
  data () {
    return {}
  },
  methods: {
    updateMapFunc () {
      let ids = []
      for (const item of this.mapConfigData) {
        ids = ids.concat(item.checkIds)
      }
      this.axios.post(api.updateUserConfigMap,
        { id: ids.join(',') }
      )
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '修改地图配置成功！'
            })
            this.$emit('editConfigEmit')
          }
        })
    },
    hideConfigFunc () {
      this.$emit('hideConfig')
    }
  }
}
</script>

<style lang="stylus" scoped>
.map-config
  position relative
  height 100vh
.map-configure-wrap
  .map-configure
    position relative
    // height 420px
    border-radius 6px
    margin auto
    box-sizing border-box
    padding-bottom 30px
    .el-icon-close
      margin-left auto
      margin-right 24px
      cursor pointer
      color #fff
    h3
      height 70px
      color #fff
      font-size 16px
      font-weight 800
      padding-left 24px
      background #32343B
      display flex
      align-items center
    .configure-content
      margin-top 25px
      .content-item
        .info-title
          position relative
          font-size 16px
          width 260px
          height 28px
          line-height 28px
          padding-left 6px
          color #fff
          font-weight 500
          margin-left 24px
          span
            color #808191
            font-size 12px
          &:after
            content ''
            display inline-block
            position absolute
            left 0
            top 7.5px
            width 2px
            height 14px
            background #F89407
            z-index 11
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 150px
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
</style>
