<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-visualiza-project-wrap"
  >
    <div v-if="hasPermission">
      <div class="w-project-detail">
        <h3 class="w-title-gradients">招商过程概览</h3>
        <div class="w-detail-right">
          <div class="w-detail-item">
            <div class="w-item-text">招商完成率<el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <em>
                  <span class="icon-mark"></span>
                </em>
                <div slot="content">已签订正式合同的铺位建筑面积(去重)/所有铺位建筑面积</div>
              </el-tooltip>
            </div>
            <div class="w-item-number">{{processOverview.berthOk}}<span style="font-size: 16px;">%</span></div>
            <div class="w-item-tips">
              <div>未签约面积 <span>{{processOverview.notSignedArea?Number(processOverview.notSignedArea).toFixed(2):'-'}}m²</span> </div>
              <div>已签约面积 <span>{{processOverview.signedArea?Number(processOverview.signedArea).toFixed(2):'-'}}m²</span> </div>
            </div>
          </div>
          <div class="w-detail-item">
            <div class="w-item-text">已租铺位<el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <em>
                  <span class="icon-mark"></span>
                </em>
                <div slot="content">当前已签约的铺位数量</div>
              </el-tooltip>
            </div>
            <div class="w-item-number"> {{processOverview.rentBerthNum}} </div>
            <div class="w-item-tips">
              <div>未签约铺位 <span>{{processOverview.notSignedBerthNum}}</span> </div>
              <div>在招商铺位 <span>{{processOverview.taskBerthNum}}</span> </div>
            </div>
          </div>
          <div class="w-detail-item">
            <div class="w-item-text">跟进中品牌<el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <em>
                  <span class="icon-mark"></span>
                </em>
                <div slot="content">当前项目正在跟进中的品牌数量</div>
              </el-tooltip>
            </div>
            <div class="w-item-number"> {{processOverview.followBrandNum}}<span>{{processOverview.followBrandNum !== 0 ? '': ''}}</span></div>
            <div class="w-item-tips">
              <div>已签约品牌 <span>{{processOverview.signedbrandNum}}</span> </div>
              <div>待跟进品牌 <span>{{processOverview.toBeFollowBrandNum}}</span> </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="w-plan-tabs">
        <span
          @click="tabsClick('brandPlan')"
          :class="[compName === 'brandPlan' ? 'active' : '']"
        >品牌落位规划</span>
        <span
          @click="tabsClick('schemeData')"
          :class="[compName === 'schemeData' ? 'active' : '']"
        >方案数据推算</span>
        <router-link
          tag="a"
          :to="{ path: '/knowledgeBase/327'}"
          target="_blank"
          class="fr"
        >操作指引</router-link>
      </div> -->
      <div class="w-select-project-wrap">
        <div class="w-title">
          <h3 class="w-title-gradients">招商过程可视化</h3>
        </div>
        <!-- <div class="w-project-name w-public-select-border">
          <el-select
            popper-class="w-block-select-down"
            v-model="planId"
            @change="tabTask"
            placeholder="请选择"
          >
            <el-option
              v-for="item in planList"
              :key="item.id"
              :label="item.planName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="w-btns-sty">
          <span @click="toTrimPlanning()"><i class="icon-font icon-signed"></i>添加签约品牌</span>
          <span @click="addTaskBrand(1)"><i class="icon-font icon-investment"></i>发布招商任务</span>
          <span @click="addTaskBrand(2)"><i class="icon-font icon-brand-follow"></i>添加品牌跟进</span>
          <!-- <span @click="addDropBrand(0)"><i class="icon-font icon-landing-plan"></i>添加计划品牌</span>
          <span @click="addTaskBrand(0)"><i class="icon-font icon-landing-plan"></i>发布招商任务</span> -->
          <span
            class="hide-mask"
            @mouseover="showMoreFunction()"
          ><i
              class="icon-font icon-export-file"
              @mouseover="showMoreFunction()"
            ></i>导出
            <div
              class="w-more-wrap"
              v-show="moreShow"
              @mouseleave="hideMoreFunction()"
            >
              <div
                class="more-item"
                @click="exportInvestment"
              >
                导出铺位招商列表
              </div>
              <div
                class="more-item"
                @click="toExportLink()"
              >
                导出招商跟进图
              </div>
            </div>
          </span>
          <!-- <div
            class="w-more-wrap"
            v-show="moreShow"
          >
            <div
              class="more-item"
              @click="addTaskPlan('add')"
            >新增落位方案</div>
            <div
              class="more-item"
              @click="copyPlan()"
            >复制当前方案</div>
            <div
              class="more-item"
              @click="addTaskPlan('edit')"
            >修改方案名称</div>
            <div
              class="more-item"
              @click="batchAddDropBrnad('plan')"
            >导入计划品牌</div>
            <div
              class="more-item"
              @click="toExport()"
            >导出落位方案</div>
            <div
              class="more-item"
              @click="deletePlan()"
            >删除落位方案</div>
          </div> -->
        </div>
      </div>
      <div>
        <div
          class="w-map-wrap"
          v-if="compName === 'brandPlan'"
        >
          <div class="map-btns">
            <div class="map-btns-info">
              <!-- <span
                class="yt-type"
                :class="[picType==='0'?'active':'']"
                @click="mapType('0')"
              >按业态落位</span> -->
              <span
                class="yt-type"
                :class="[picType==='2'?'active':'']"
                @click="mapType('2')"
              >铺位招商进度</span>
              <span
                class="speed-type"
                :class="[picType==='1'?'active':'']"
                @click="mapType('1')"
              >品牌跟进进度</span>
            </div>
          </div>
          <div class="map-building-select w-input-sty">
            <el-select
              popper-class="w-block-select-down"
              v-model="buildingId"
              @change="changebuilding"
              placeholder="请选择"
            >
              <el-option
                v-for="item in buildingList"
                :key="item.buildingCode"
                :label="item.buildingName"
                :value="item.buildingCode"
              >
              </el-option>
            </el-select>
          </div>
          <iframe
            id="map"
            :src="iframeUrl"
            frameborder="0"
            width="100%"
            height="800px"
            ref="childrenIframe"
          ></iframe>
          <img
            class="setting"
            @click="showConfigFunc"
            src="@/assets/images/workBench/mapConfig.png"
            alt=""
          >
          <div
            class="positon-a plan-icon-list"
            v-if="picType === '0'"
          >
            <template v-for="item in iconList">
              <img
                :key="item.industry"
                :src="item.img"
                alt=""
              >{{item.industry}}
            </template>
            <!-- <img
              src="@/assets/images/planProject/weq.png"
              alt=""
            >儿童亲子
            <img
              src="@/assets/images/planProject/wwy.png"
              alt=""
            >文体娱
            <img
              src="@/assets/images/planProject/wsf.png"
              alt=""
            >生活服务
            <img
              src="@/assets/images/planProject/wls.png"
              alt=""
            >零售
            <img
              src="@/assets/images/planProject/wkd.png"
              alt=""
            >跨界结合店
            <img
              src="@/assets/images/planProject/wqt.png"
              alt=""
            >其它类型 -->
          </div>
          <div
            class="positon-a map-font-color"
            v-if="picType === '1'"
          >
            <span><i style="background: rgba(161, 105, 40, 1);"></i>待跟进</span>
            <span><i style="background: rgba(52, 70, 180, 1);"></i>已联系</span>
            <span><i style="background: rgba(14, 153, 151, 1);"></i>已看场</span>
            <span><i style="background: rgba(148, 140, 49, 1);"></i>已报价</span>
            <span><i style="background: rgba(143, 63, 22, 1);"></i>已预定</span>
            <span><i style="background: rgba(9, 151, 70, 1);"></i>已签约</span>
            <span><i style="background: rgba(81, 45, 101, 1);"></i>待招商</span>
            <span><i style="background: rgba(153, 153, 153, 1);"></i>已搁置/无意向</span>
          </div>
          <div
            class="map-font-color"
            style="margin-left: -190px;"
            v-if="picType === '2'"
          >
            <span><i style="background: rgba(156, 172, 101, 1);"></i>继续招商中</span>
            <span><i style="background: rgba(9, 151, 70, 1);"></i>已签约</span>
            <span><i style="background: rgba(136, 92, 178, 1);"></i>已预定</span>
            <span><i style="background: rgba(32, 214, 254, 1);"></i>招商中</span>
            <span v-if="domainIsWinshang"><i style="background: rgba(81, 45, 101, 1);"></i>待招商</span>
            <span v-else><i style="background: #dd5e1d;"></i>待招商</span>
          </div>
        </div>
        <div v-if="compName === 'schemeData'">
          <keep-alive>
            <schemeData
              v-if="projectId && planId"
              :projectId="projectId"
              :planId="planId"
              :entiretyData="entiretyData"
            ></schemeData>
          </keep-alive>
        </div>
      </div>
      <!-- 5.5版本新加 -->
      <el-drawer
        class="black-drawer"
        size="592px"
        :with-header="false"
        :visible.sync="configShow"
        direction="rtl"
        ref="drawer"
      >
        <div v-if="configShow">
          <map-config
            :mapConfigData="mapConfigData"
            @hideConfig="hideConfig"
            @editConfigEmit="editConfigEmit"
          ></map-config>
        </div>
      </el-drawer>
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="isInvestment"
        :before-close="beforeCloseBrandFollow"
        direction="rtl"
      >
        <div v-if="isInvestment && editType">
          <brandFollow
            @showAddBrand="showAddBrand"
            @selectProjectIdEmit="selectProjectIdEmit"
            @releaseBrandSuccessEmit="releaseBrandSuccessEmit"
            @hideBrandFollowEmit="hideBrandFollowEmit"
            :projectId="projectId"
            :editType="editType"
            :issueTaskArr="issueTaskArr"
          ></brandFollow>
        </div>
        <el-drawer
          class="black-drawer"
          size="907px"
          :with-header="false"
          :append-to-body="true"
          :before-close="hideAddBrand"
          :visible.sync="addBrandSync"
        >
          <div v-if="projectIdcheck > 0">
            <addBrand
              @closeAddBrand="closeAddBrand"
              @addBrandEmit="addBrandEmit"
              :projectIdcheck="projectIdcheck"
              :editType="editType"
              :issueTaskArr="issueTaskArr"
            ></addBrand>
          </div>
        </el-drawer>
      </el-drawer>
      <!-- 5.5版本新加 -->
      <batch-Brand
        v-if="batchShow"
        :importType="importType"
        @addDropBrandEmitSuccess="addDropBrandEmitSuccess"
        @closeFuc="closeFuc"
      ></batch-Brand>
      <el-drawer
        class="black-drawer"
        size="500px"
        :with-header="false"
        :visible.sync="isBrandDetails"
        direction="rtl"
        ref="drawer"
        :style="{'right': drawerRight + 'px'}"
      >
        <div v-if="isBrandDetails">
          <mapbrand-Details
            v-if="projectId"
            :brandDetails="brandDetails"
            :projectId="projectId"
            :type="2"
            @stopBrandEmit="stopBrandEmit"
            @toInvestmentEmit="toInvestmentEmit"
            @changeBrandStateEmit="changeBrandStateEmit"
            @addSingedEmit="addSingedEmit"
            @deletePlanEmit="deletePlanEmit"
            @addDropBrandEmit="addDropBrandEmit"
            @editDropBrandEmit="editDropBrandEmit"
            @hideMapDetails="hideMapDetails"
            @deleteSingedEmit="deleteSingedEmit"
            @editSingedBrandEmit="editSingedBrandEmit"
            @selectRecommendEmit="selectRecommendEmit"
            @toContractDetailEmit="toContractDetailEmit"
            @editBrandStatusEmit="editBrandStatusEmit"
          ></mapbrand-Details>
        </div>
        <el-drawer
          class="black-drawer"
          size="907px"
          :with-header="false"
          :append-to-body="true"
          :before-close="hideDropBrand"
          :visible.sync="investmentShow"
        >
          <div v-if="investmentShow">
            <edit-states
              :invesmtentItem="invesmtentItem"
              @hideInvestmentEmit="hideInvestmentEmit"
            ></edit-states>
          </div>
        </el-drawer>
      </el-drawer>
      <el-drawer
        size="599px"
        :with-header="false"
        :visible.sync="dropBrand"
        custom-class="w-block-dialog"
        direction="rtl"
        :before-close="hidePositionBrand"
      >
        <positionBrandManagement
          v-if="dropBrand && projectId"
          :projectId='projectId'
          :dropEdit='dropEdit'
          :recommendObj='recommendObj'
          :brandDetails='brandDetails'
          :delset='delset'
          :type="2"
          @hidePositionBrand='hidePositionBrand'
        ></positionBrandManagement>
      </el-drawer>
      <div v-if="singleBrandShow">
        <single-Brand
          v-if="projectId"
          :edit="edit"
          :projectId="projectId"
          :projectName="projectName"
          :singedIdx="singedIdx"
          :brandDetails="brandDetails"
          @addSuccessFunc="addSuccessFunc"
          @hideSingle="hideSingle"
        ></single-Brand>
      </div>
      <div v-if="taskBrand">
        <inves-task
          v-if="projectId"
          :selectProjectId="projectId"
          :taskEdit="taskEdit"
          :planIdx="planIdx"
          :projectName="projectName"
          :brandDetails="brandDetails"
          @hideTaskFunc="hideTaskFunc"
        ></inves-task>
      </div>
      <div v-if="maskShow">
        <add-mask
          v-if="invesmtentItem !== {}"
          :invesmtentItem="invesmtentItem"
          :projectId="projectId"
          :projectName="projectName"
          :maskEdit='0'
          @hideSingleMask="hideSingleMask"
        ></add-mask>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import schemeData from '@/views/workBench/components/schemeData.vue'
import mapbrandDetails from '@/views/workBench/components/mapBrandDetails.vue'
// import addDrop from '@/views/workBench/components/addDropBrand.vue'
import positionBrandManagement from '@/views/workBench/components/positionBrandManagement.vue'
import singleBrand from '@/views/workBench/components/addSingleBrand.vue'
import invesTask from '@/views/workBench/components/investmentTask.vue'
import batchBrand from '@/views/workBench/components/batchDropBrand.vue'
import editStates from '@/views/workBench/components/investmentEditStates.vue'
import addMask from '@/views/workBench/components/addSingleMask.vue'
import brandFollow from '@/views/workBench/components/brandFollow.vue'
import addBrand from '@/views/workBench/components/addBrand.vue'
import mapConfig from '@/views/workBench/components/mapConfig.vue'
import { getCookie, powerAuth } from '@/utils'
import Bus from '@/utils/bus.js'
export default {
  data () {
    return {
      loading: true,
      compName: 'brandPlan',
      projectHeadData: {},
      planList: [],
      planId: '',
      planName: '',
      berthId: '',
      moreShow: false,
      planType: 'add',
      entiretyData: {},
      picType: '2',
      isBrandDetails: false,
      brandDetails: {},
      iframeUrl: '',
      hideDetails: true,
      dropBrand: false,
      taskBrand: false,
      taskEdit: 0,
      planIdx: 0,
      dropEdit: 0,
      singleBrandShow: false,
      edit: 0,
      singedIdx: 0,
      guideShow: false,
      batchShow: false,
      importType: '',
      typeStr: '',
      invesmtentItem: {},
      investmentShow: false,
      maskShow: false,
      userAuthorities: [],
      hasPermission: true, // 还有一个show，用这个判断就好了d
      addset: true, // 新建落位方案d
      editset: true, // 修改方案名称d
      copyset: true, // 复制落位方案d
      delset: true, // 删除落位方案d
      leadingset: true, // 导出落位方案d
      addbrand: true, // 添加计划品牌、导入计划品牌d
      delbrand: true, // 删除计划品牌d
      pubplan: true, // 发布招商任务d
      addsignbrand: true, // 添加签约品牌d
      hasPermissionLeadingout: true, // 导出：指“导出”的操作；d
      configShow: false,
      modulesList: [],
      recommendObj: {},
      buildingId: '',
      buildingList: [],
      iconList: [],
      isInvestment: false,
      addBrandSync: false,
      projectIdcheck: 0,
      mapConfigData: [],
      processOverview: {},
      editType: 1,
      drawerRight: 0,
      issueTaskArr: []
    }
  },
  props: {
    projectId: {
      type: Number
    },
    projectName: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    domainIsWinshang: function () {
      return document.domain.indexOf('winshangdata.com') > -1
    }
  },
  components: {
    schemeData,
    mapbrandDetails,
    positionBrandManagement,
    singleBrand,
    batchBrand,
    invesTask,
    editStates,
    addMask,
    brandFollow,
    addBrand,
    mapConfig
  },
  watch: {
    projectId (o, n) {
      this.projectIdcheck = o
      if (o > 0) {
        this.getGdBuildingFunc()
        this.getBerthTaskOverviewFunc()
      }
    },
    dropBrand (n) {
      if (n) {
        document.body.style.overflowY = 'hidden'
      } else {
        this.getShopDetails(this.berthId).then((res) => {
          this.isBrandDetails = true
        })
      }
    },
    isBrandDetails (n) {
      if (n) {
        document.body.style.overflowY = 'hidden'
      } else {
        if (!this.dropBrand) {
          document.body.style.overflowY = 'auto'
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('message', this.postMessage)
    window.removeEventListener('click', this.closeClick, false)
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 215
    })
    if (this.userAuthorities.length) {
      this.$nextTick(() => {
        this.getUserConfigFunc()
        this.getModularListFunc()
        this.getIndustryIconFunc()
        if (this.projectId) {
          this.projectIdcheck = this.projectId
          this.getGdBuildingFunc()
          this.getBerthTaskOverviewFunc()
        }
        this.childrenIframe = this.$refs.childrenIframe.contentWindow
        window.addEventListener('message', this.postMessage, false)
        window.addEventListener('click', this.closeClick, false)
        const iframe = document.getElementById('map')
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', () => {
            // iframe加载完成后要进行的操作
            this.loading = false
          })
        } else {
          iframe.onload = () => {
            // iframe加载完成后要进行的操作
            this.loading = false
          }
        }
      })
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.addset = this.userAuthorities[0].func.indexOf('addset') !== -1
      this.editset = this.userAuthorities[0].func.indexOf('editset') !== -1
      this.copyset = this.userAuthorities[0].func.indexOf('copyset') !== -1
      this.delset = this.userAuthorities[0].func.indexOf('delset') !== -1
      this.leadingset = this.userAuthorities[0].func.indexOf('leadingset') !== -1
      this.addbrand = this.userAuthorities[0].func.indexOf('addbrand') !== -1
      this.delbrand = this.userAuthorities[0].func.indexOf('delbrand') !== -1
      this.pubplan = this.userAuthorities[0].func.indexOf('pubplan') !== -1
      this.addsignbrand = this.userAuthorities[0].func.indexOf('addsignbrand') !== -1
    } else {
      this.hasPermission = false
      this.loading = false
    }
    Bus.$on('removeAddBrandListBusEmit', () => {
      this.issueTaskArr = []
    })
  },
  methods: {
    hideMoreFunction () {
      this.moreShow = false
    },
    hideDropBrand () {
      this.investmentShow = false
    },
    editBrandStatusEmit (item) {
      console.log(item)
      this.invesmtentItem.brandName = item.brand.brandName
      this.invesmtentItem.taskState = item.task.taskState
      this.invesmtentItem.followUserName = item.task.followUserName
      this.invesmtentItem.lastFollowTime = item.task.lastFollowTime
      this.invesmtentItem.taskId = item.task.id
      this.investmentShow = true
    },
    getBerthTaskOverviewFunc () {
      this.axios.post(api.getBerthTaskOverview, { projectId: this.projectId })
        .then((res) => {
          this.processOverview = res.data.data
        })
    },
    getIndustryIconFunc () {
      this.axios.post(api.getIndustryIcon)
        .then((res) => {
          this.iconList = res.data.data
        })
    },
    getGdBuildingFunc () {
      this.axios.post(api.getGdbuilding, { projectId: this.projectId })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.buildingId = res.data.data[0].buildingCode
          }
          this.buildingList = res.data.data
          if (this.buildingId) {
            this.reloadMap()
          }
        })
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    sendMessageToIframe (message) {
      this.childrenIframe.postMessage(message, '*')
    },
    postMessage (e) {
      if (e.data.code && e.data.code === -2) {
        this.$alert('当前项目缺失室内地图数据，请联系相关赢商商务人员进行处理！', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        })
      }
      if (e.data.show === true || e.data.show === undefined) {
        this.hideDetails = true
      } else {
        this.hideDetails = false
      }
      if (e.data.ss) {
        this.berthId = e.data.ss
        this.getShopDetails(e.data.ss)
      }
    },
    getShopDetails (id) {
      this.loading = true
      return new Promise((resolve) => {
        this.axios.post(api.getMapBrandDetails, { berthId: id, decisionId: 0 })
          .then((res) => {
            if (res.data.data) {
              this.brandDetails = res.data.data
              this.brandDetails.planBrands = res.data.data.planBrands.map((item) => {
                if (item.task.taskState === '待跟进') {
                  item.task.color = 'rgba(240,151,47,1)'
                } else if (item.task.taskState === '已联系') {
                  item.task.color = 'rgba(54,78,225,1)'
                } else if (item.task.taskState === '已看场') {
                  item.task.color = 'rgba(13,172,177,1)'
                } else if (item.task.taskState === '已报价') {
                  item.task.color = 'rgba(233,203,0,1)'
                } else if (item.task.taskState === '已预定') {
                  item.task.color = 'rgba(218,96,34,1)'
                } else if (item.task.taskState === '已签约') {
                  item.task.color = 'rgba(16,204,97,1)'
                } else if (item.task.taskState === '已搁置') {
                  item.task.color = 'rgba(177,177,177,1)'
                } else if (item.task.taskState === '无意向') {
                  item.task.color = 'rgba(177,177,177,1)'
                } else if (item.task.taskState === '待招商') {
                  item.task.color = 'rgba(134,52,180,1)'
                } else if (item.task.taskState === '未发布') {
                  item.task.color = '#FF5B5B'
                }
                return item
              })
              if (this.hideDetails) {
                this.isBrandDetails = true
              } else {
                this.isBrandDetails = false
              }
              resolve(this.brandDetails)
            }
            this.loading = false
          })
      })
    },
    changebuilding (val) {
      this.reloadMap()
    },
    showConfigFunc () {
      this.getUserConfigFunc()
      this.configShow = true
    },
    hideConfig () {
      this.configShow = false
    },
    getUserConfigFunc () {
      this.axios.post(api.getUserConfigMap, { type: 2 })
        .then((res) => {
          this.mapConfigData = res.data.data.map((item) => {
            item.checkIds = item.lableList.filter(item => item.isShow === 1).map(x => x.id)
            return item
          })
        })
    },
    // 5.5废弃
    tabTask (val) {
      this.planId = val
      // console.log(this.planId)
      let data = {}
      data = this.planList.filter((item) => {
        return item.id === val
      })
      this.planName = data[0].planName
      this.reloadMap()
    },
    addDropBrand (index) {
      if (!this.addbrand) {
        this.hasnotPermission()
        return false
      }
      this.brandDetails = {}
      this.dropEdit = index
      this.dropBrand = true
      this.taskBrand = false
      this.guideShow = false
      document.body.style.overflowY = 'hidden'
    },
    addDropBrandEmit () {
      if (!this.addbrand) {
        this.hasnotPermission()
        return false
      }
      this.dropEdit = 5
      this.recommendObj = {}
      this.dropBrand = true
      this.isBrandDetails = false
      document.body.style.overflowY = 'hidden'
    },
    hidePositionBrand (type) {
      if (type) {
        this.refreshMap()
      }
      this.recommendObj = {}
      this.dropBrand = false
    },
    addDropBrandEmitSuccess () {
      this.recommendObj = {}
      // this.getPlanEntirety()
      this.refreshMap()
    },
    editDropBrandEmit () {
      this.dropBrand = true
      this.isBrandDetails = false
    },
    deletePlanEmit (item) {
      if (item.task.taskState === '待招商' || item.task.taskState === '无意向' || item.task.taskState === '已搁置') {
        // 删除落位不提示
        this.$confirm('确定要删除"' + item.brand.brandName + '"吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.delBerthPlanBrand, { id: item.id })
            .then((res) => {
              this.isBrandDetails = false
              this.refreshMap()
            })
        })
      } else if (item.task.taskState === '已联系' || item.task.taskState === '已报价' || item.task.taskState === '已预定' || item.task.taskState === '已看场' || item.task.taskState === '待跟进') {
        // 删除落位要提示
        this.$confirm('当前落位品牌正在招商中', '确定要删除"' + item.brand.brandName + '"吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.delBerthPlanBrand, { id: item.id })
            .then((res) => {
              this.isBrandDetails = false
              this.refreshMap()
            })
        })
      } else if (item.task.taskState === '已签约') {
        // 删除签约删除落位要提示
        this.$confirm('当前落位品牌已经签约，删除后将同步删除签约数据', '确定要删除"' + item.brand.brandName + '"吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.delBerthPlanBrand, { id: item.id })
            .then((res) => {
              this.isBrandDetails = false
              this.refreshMap()
            })
        })
      }
    },
    addSuccessFunc (type) {
      if (type) {
        // this.getPlanEntirety()
        document.body.style.overflowY = 'auto'
        this.singleBrandShow = false
        this.refreshMap()
      }
    },
    editSingedBrandEmit (index) {
      this.singedIdx = index
      this.edit = 7
      this.singleBrandShow = true
      this.isBrandDetails = false
      // this.guideShow = false
      document.body.style.overflowY = 'hidden'
    },
    addSingedEmit () { // 子组件添加签约品牌
      if (!powerAuth(215, 'addsignbrand')) {
        this.hasnotPermission()
        return false
      }
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
          this.statesIdx = null
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
      this.isBrandDetails = false
      document.body.style.overflowY = 'auto'
    },
    changeBrandStateEmit (item, state) {
      this.typeStr = state
      this.invesmtentItem = item
      if (state === '已签约') {
        // 变更招商状态为签约品牌
        // this.invesmtentItem = item
        // this.maskShow = true
        // this.isBrandDetails = false
        // document.body.style.overflowY = 'hidden'
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
            this.statesIdx = null
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else if (state === '已预定') {
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/intentionContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
            this.statesIdx = null
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else {
        // 修改为其他
        this.investmentShow = true
        this.isBrandDetails = false
        document.body.style.overflowY = 'hidden'
      }
    },
    deleteSingedEmit (item) {
      // 删除签约删除落位要提示
      this.$confirm('当前落位品牌已经签约，删除后将同步删除签约数据', '确定要删除"' + item.brand.brandName + '"吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        customClass: 'invitePosition'
      }).then((res) => {
        this.axios.post(api.delSingleBrand, { id: item.id })
          .then((res) => {
            this.isBrandDetails = false
            this.refreshMap()
          })
      })
    },
    hideSingle (index) {
      this.singleBrandShow = false
      document.body.style.overflowY = 'auto'
    },
    addTaskBrand (type, index, arr) {
      if (type === 1) {
        if (!powerAuth(217, 'pubplan')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      } else {
        if (!powerAuth(217, 'addfollow')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      }
      // if (type === 1 && arr && arr.length > 0) {
      //   const gbidsList = arr.map((item) => {
      //     return item.gbid
      //   })
      //   this.$store.dispatch('setGbIdsList', gbidsList)
      //   this.$store.dispatch('setInvestmentBrandList', arr)
      // } else {
      //   this.$store.dispatch('setInvestmentBrandList', [])
      //   this.$store.dispatch('setGbIdsList', [])
      // }
      this.issueTaskArr = arr
      this.editType = type
      this.isInvestment = true
    },
    toInvestmentEmit (type, index, arr) {
      if (!powerAuth(217, 'pubplan')) {
        this.hasnotPermission()
        return false
      }
      this.addTaskBrand(type, index, arr)
    },
    hideTaskFunc (type) {
      if (type) {
        // this.getPlanEntirety()
        this.refreshMap()
      }
      this.taskBrand = false
      document.body.style.overflowY = 'auto'
    },
    // 添加签约品牌
    toTrimPlanning () {
      if (!this.addsignbrand) {
        this.hasnotPermission()
        return false
      }
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
          this.statesIdx = null
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
      // this.edit = 0
      // this.singleBrandShow = true
      // this.isBrandDetails = false
      // document.body.style.overflowY = 'hidden'
    },
    toContractDetailEmit (item) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + item[0].contractCode + '&contractId=' + item[0].contractId + '&mallCode=' + this.projectId + '&menuCode=12&tokenval=' + getCookie('wisdom_token') + '&operateStep=3', '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
        // /omsviews/rent/contract/intentionContract/details/mainBodyInfo?contractCode=7988-GDWS20201223001&contractId=1341663395865489410&mallCode=7988
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    selectRecommendEmit (item, type) {
      this.type = type
      this.recommendObj = item
      this.dropBrand = true
      this.isBrandDetails = false
      document.body.style.overflowY = 'hidden'
    },
    hideSingleMask (type) {
      if (type) {
        // this.getPlanEntirety()
        this.refreshMap()
      }
      this.maskShow = false
      setTimeout(() => {
        document.body.style.overflowY = 'auto'
      }, 500)
    },
    tabsClick (name) {
      this.compName = name
    },
    showMoreFunction () {
      this.moreShow = true
    },
    stopBrandEmit () {
      this.refreshMap()
      this.isBrandDetails = false
      setTimeout(() => {
        document.body.style.overflowY = 'auto'
      }, 600)
    },
    toContrast () {
      if (this.entiretyData.plannedBrandsNum === 0 && this.entiretyData.brandSignedNum === 0) {
        this.$alert('方案中落位品牌数据为空，请先添加签约品牌和计划落位数据！', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        })
      } else {
        const trimBrandPlanHref = this.$router.resolve({
          path: '/contrast',
          query: {
            id: this.projectId
          }
        })
        window.open('/' + trimBrandPlanHref.href, '_blank')
      }
    },
    // 获取方案整体数据
    getPlanEntirety () {
      this.axios.post(api.getPlanEntiretyData, { projectId: this.projectId, planId: this.planId })
        .then((res) => {
          this.entiretyData = res.data.data
          this.echartsData = this.entiretyData
          // if (this.planIsFirst === 1) {
          //   this.planNumber = this.entiretyData.plannedBrandsNum
          //   setTimeout(() => {
          //     if (this.planNumber === 0 && this.entiretyData.brandSignedNum === 0) {
          //       this.guideShow = true
          //       document.body.style.overflowY = 'hidden'
          //     }
          //   }, 300)
          // }
        })
    },
    // 新增方案
    addTaskPlan (type) {
      if (!this.addset && type === 'add') {
        this.hasnotPermission()
        return false
      }
      if (!this.editset && type === 'edit') {
        this.hasnotPermission()
        return false
      }
      if (this.entiretyData.plannedBrandsNum === 0 && this.entiretyData.brandSignedNum === 0) {
        this.moreShow = false
        this.$alert('“首选方案”品牌数据为空，请先添加签约品牌和计划落位数据！', '提示', {
          confirmButtonText: '确定',
          center: true,
          callback: action => {
          }
        })
      } else {
        this.planType = type
        const title = (type === 'edit' ? '编辑' : '添加') + '品牌落位方案'
        const id = type === 'edit' ? this.planId : ''
        this.$prompt(' ', title, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'inputClass',
          inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9_]{0,8}$/,
          inputType: 'text',
          center: true,
          inputValue: type === 'edit' ? this.planName : '',
          inputErrorMessage: '不能输入超过8个字且不包含特殊字符!',
          inputPlaceholder: '最多可以输入8个字以内且不包含特殊字符'
        }).then(({ value }) => {
          this.axios.post(api.savetaskPlanName, { planName: value, projectId: this.projectId, id: id })
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '添加成功!'
                })
              }
            })
        })
      }
    },
    copyPlan () {
      if (!this.copyset) {
        this.hasnotPermission()
        return false
      }
      this.copyPlanFunc().then((res) => {
        if (res.data.data) {
          this.axios.post(api.copyPlan, { id: this.planId })
            .then((res) => {
              this.$message({
                type: 'success',
                message: '复制成功！'
              })
            })
        } else {
          this.$message({
            type: 'warning',
            message: '当前落位方案品牌数据为空，请先添加签约品牌和计划落位数据！'
          })
        }
      })
    },
    copyPlanFunc () {
      return new Promise((resolve, reject) => {
        this.axios.post(api.isBerthPlan, { id: this.planId })
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error('wdnmd'))
            }
          })
      })
    },
    hideInvestmentEmit (type) {
      if (type) {
        this.isBrandDetails = false
        this.getShopDetails(this.berthId).then((res) => {
          this.isBrandDetails = true
          this.refreshMap()
        })
      }
      this.investmentShow = false
      document.body.style.overflowY = 'auto'
    },
    showConfim () {
      this.axios.post(api.countPpyAddLink, { gbid: this.invesmtentItem.gbid })
        .then((res) => {
          if (res.data.data === 0) {
            this.$confirm('是否需要同步添加品牌联系人数据？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: 'invitePosition'
            }).then((res) => {
              this.addContact()
            })
          }
        })
    },
    addContact () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'https://' + document.domain + ':8030'
      }
      window.open(temUrl + '/brand/#/issue/addContact?brandId=' + this.invesmtentItem.brandId + '&gbid=' + this.invesmtentItem.gbid + '&brandName=' + encodeURIComponent(encodeURIComponent(this.invesmtentItem.brand.brandName)) + '&taskId=' + this.invesmtentItem.task.id + '&from=investment', '_blank')
    },
    // 删除方案
    deletePlan () {
      if (this.delset) {
        if (this.planName === '首选方案') {
          this.$message({
            type: 'warning',
            message: '首选方案不可删除！'
          })
          return false
        }
        this.$confirm(this.entiretyData.announcedNum === 0 ? ' ' : '当前落位方案存在正在招商中的品牌', '确定删除' + this.planName + '?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.delPlanTask, { id: this.planId })
            .then((res) => {
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
            })
        })
      } else {
        this.hasnotPermission()
      }
    },
    mapType (type) {
      this.picType = type
      this.reloadMap()
    },
    reloadMap () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://localhost:8001'
      } else if (window.location.href.indexOf('http://') > -1) {
        temUrl = 'http://' + document.domain + '/map'
      } else {
        temUrl = 'https://' + document.domain + '/map'
      }
      this.iframeUrl = temUrl + '/planMap.html?token=' +
        getCookie('wisdom_token') +
        '&taskId=' + this.planId +
        '&projectId=' + this.projectId +
        '&picType=' + this.picType +
        '&buildingId=' + this.buildingId
    },
    hideMapDetails () {
      this.isBrandDetails = false
      // document.body.style.overflowY = 'auto'
    },
    closeClick (e) {
      if (e.target.className.indexOf('hide-mask') === -1) {
        this.moreShow = false
      }
      // if (e.target.className.indexOf('follow-wrap') === -1) {
      //   this.investmentShow = false
      // }
    },
    toExport () {
      if (this.leadingset) {
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        // demo
        this.exportPlan().then(res => { // 处理返回的文件流
          const blob = new Blob([res.data])
          const fileName = '【' + this.projectName + '】' + '【' + this.planName + '】' + '品牌落位清单-' + year + '-' + month + '-' + day + '.xlsx'
          // 兼容ie
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName)
            this.loading = false
          } else {
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            setTimeout(() => {
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            }, 1000)
          }
        })
      } else {
        this.hasnotPermission()
      }
    },
    exportPlan () {
      return this.axios({ // 用axios发送post请求
        method: 'post',
        url: api.exportPlanapi, // 请求地址
        data: { projectId: this.projectId }, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    // 批量导入落位
    batchAddDropBrnad (type) {
      if (!this.addbrand) {
        this.hasnotPermission()
        return false
      }
      this.importType = type
      this.batchShow = true
      document.body.style.overflowY = 'hidden'
    },
    closeFuc () {
      this.batchShow = false
      document.body.style.overflowY = 'auto'
    },
    editConfigEmit () {
      this.configShow = false
      this.refreshMap()
    },
    refreshMap () {
      this.sendMessageToIframe({
        cmd: 'tabChange',
        message: {
          decisionId: 0,
          token: getCookie('wisdom_token'),
          projectId: this.projectId,
          buildingId: this.buildingId,
          type: 'refresh'
        }
      }, '*')
    },
    hideAddBrand () {
      this.addBrandSync = false
    },
    showAddBrand () {
      this.addBrandSync = true
    },
    hideBrandFollowEmit () {
      Bus.$emit('removeAddBrandListBusEmit')
      this.isInvestment = false
    },
    beforeCloseBrandFollow () {
      Bus.$emit('removeAddBrandListBusEmit')
      this.isInvestment = false
    },
    closeAddBrand () {
      this.addBrandSync = false
    },
    addBrandEmit () {
      this.addBrandSync = false
    },
    selectProjectIdEmit (id) {
      this.projectIdcheck = id
    },
    releaseBrandSuccessEmit () {
      this.isBrandDetails = false
      this.isInvestment = false
      this.getShopDetails(this.berthId).then((res) => {
        this.refreshMap()
        this.isBrandDetails = true
      })
    },
    exportInvestment () {
      if (!powerAuth(215, 'leadingtask')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.loading = true
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      // demo
      this.exportPlanList().then(res => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const fileName = '【' + this.projectName + '】' + '铺位招商管理列表' + '【' + year + '-' + month + '-' + day + '】' + '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
          this.loading = false
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
          this.loading = false
        }
      })
    },
    exportPlanList () {
      const data = {
        projectId: this.projectId
      }
      return this.axios({ // 用axios发送post请求
        method: 'post',
        url: api.exportBerthList, // 请求地址
        data: data, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    toExportLink () {
      if (!powerAuth(215, 'leadingtaskimg')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      // const Urlhref = this.$router.resolve({
      //   path: '/exportPdf/landingPlanPdf',
      //   query: {
      //     id: this.projectId,
      //     picType: this.picType,
      //     from: 2
      //   }
      // })
      // window.open('/' + Urlhref.href, '_blank')
      window.open(`report.html#/exportPdf/landingPlanPdf?buildCode=${this.buildingId}&id=${this.projectId}&picType=${this.picType}&from=2`, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-visualiza-project-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
.w-project-detail
  background-color #272930
  position relative
  .w-detail-right
    display flex
    margin-right 32px
    padding 22px 0 20px 0
    justify-content center
    .w-detail-item
      flex 1
      // background linear-gradient(0deg, #FF8239, #FFAC42)
      height 120px
      border-radius 3px
      margin-right 12px
      box-sizing border-box
      border-right 1px solid #373A43
      &:last-child
        border none
      .w-item-number
        color #fff
        font-size 30px
        font-weight 500
        margin-top 15px
        margin-bottom 10px
        text-align center
        span
          font-size 14px
      .w-item-text
        font-size 16px
        font-weight 500
        color #808191
        text-align center
        .icon-mark
          margin-left 4px
      .w-item-liner
        border 1px dashed rgba(255, 255, 255, 0.5)
        margin 6px 0
      .w-item-tips
        font-size 14px
        font-weight 500
        color #808191
        display flex
        margin auto
        >div:last-child
          width 50%
          padding-left 12px
          span
            color #fff
        >div:first-child
          width 50%
          padding-right 12px
          text-align right
          span
            color #fff
.w-plan-tabs
  height 48px
  margin-top 16px
  a
    font-size 14px
    font-weight 500
    color rgba(255, 255, 255, 0.6)
    cursor pointer
    margin-top 15px
  span
    display inline-block
    width 194px
    height 48px
    line-height 48px
    text-align center
    background #23252C
    border-radius 2px 2px 0px 0px
    margin-right 16px
    font-size 18px
    font-weight 500
    color rgba(255, 255, 255, 0.6)
    cursor pointer
    &.active
      color #FFA134
      background-color #272930
.w-select-project-wrap
  position relative
  display flex
  align-items center
  height 67px
  background-color #272930
  padding 0 32px 0 0
  margin-top 32px
  .w-title
    position absolute
    left 0
    top 0
  .w-btns-sty
    margin-left auto
    >span
      position relative
      display inline-block
      text-align center
      border-radius 3px
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin-left 32px
      cursor pointer
      &:hover
        color #FFA134
        i:before
          color #FFA134 !important
      i
        color #fff
        margin-right 5px
    .w-project-comparison
      color #FFA134
      border-color #FFA134
  .w-more-wrap
    width 140px
    height 90px
    position absolute
    right -44px
    top 28px
    background #272930
    color #fff
    font-size 14px
    z-index 1111
    border 1px solid #424752
    box-shadow 0px 16px 30px rgba(0, 0, 0, 0.6)
    .more-item
      cursor pointer
      height 44px
      line-height 44px
      &:hover
        color #FFA134
        background rgba(255, 255, 255, 0.05)
.w-map-wrap
  position relative
  height 800px
  background-color #1E1E23
  border 1px solid rgba(255, 255, 255, 0.1)
  box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
  border-radius 0px 3px 3px 3px
  .setting
    position absolute
    left 32px
    bottom 20px
    cursor pointer
.plan-icon-list
  // position absolute
  // bottom 30px
  // left 50%
  font-size 14px
  color #fff
  font-weight 500
  // margin auto
  text-align center
  // margin-left -286px
  position relative
  margin-top -40px
  img
    margin-right 5px
    margin-left 10px
    width 18px
    height 18px
    vertical-align middle
.map-font-color
  position absolute
  bottom 30px
  left 50%
  text-align center
  color #fff
  font-size 14px
  font-weight 500
  margin-left -286px
  span
    margin 0 8px
    i
      display inline-block
      width 12px
      height 12px
      border-radius 6px
      vertical-align middle
      margin-right 4px
.info-btn
  text-align center
  margin-top 30px
  span
    display inline-block
    width 200px
    height 40px
    line-height 40px
    text-align center
    color #fff
    background #FFA134
    font-size 16px
    font-weight 400
    border-radius 4px
    cursor pointer
    border 1px solid #FFA134
    &.cancal
      background #ffffff
      color #FFA134
      border 1px solid #FFA134
      margin-right 20px
.map-btns
  position absolute
  right 30px
  top 30px
  z-index 111
  .map-btns-info
    color #fff
    cursor pointer
    .yt-type
      display inline-block
      padding 7px 18px
      text-align center
      border-left 1px solid rgba(255, 255, 255, 0.2)
      border-top 1px solid rgba(255, 255, 255, 0.2)
      border-bottom 1px solid rgba(255, 255, 255, 0.2)
      border-right 1px solid rgba(255, 255, 255, 0.2)
      border-radius 3px 0px 0px 3px
      vertical-align middle
      font-size 14px
      &.active
        background-color #FFA134
    .speed-type
      display inline-block
      padding 7px 18px
      text-align center
      border-right 1px solid rgba(255, 255, 255, 0.2)
      border-top 1px solid rgba(255, 255, 255, 0.2)
      border-bottom 1px solid rgba(255, 255, 255, 0.2)
      border-radius 0px 3px 3px 0px
      vertical-align middle
      font-size 14px
      &.active
        background-color #FFA134
</style>
