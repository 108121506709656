<template>
  <div
    class="w-scheme-data-wrap"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div class="w-plan-data">
      <h3 class="w-title-gradients">方案整体数据
        <el-tooltip
          class="item"
          popper-class="planTooltip"
          effect="dark"
          placement="bottom"
        >
          <span class="el-icon-question"></span>
          <div slot="content">一个铺位多个落位计划品牌时，以首选落位品牌为基准进行分析</div>
        </el-tooltip>
      </h3>
      <div class="w-forecast-floor">
        <div class="w-forecast-floor-top">
          <div class="w-forecast-floor-content-left">
            <el-tooltip
              class="item"
              effect="dark"
              content=""
              placement="bottom"
            >
              <div slot="content">
                方案年度租金收益预测：根据落位方案中已签约品牌的签约信息和有落位计划品牌的铺位信息（铺位使用面积和铺位目标租金）计算方案的年度租金收益总额预测
                <br>平均签约租金：签约租金和租赁面积，计算对应的铺位租金之和/对应铺位使用面积之和
              </div>
              <p class="w-left-p1">方案年度租金收益预测（万）</p>
            </el-tooltip>
            <p class="w-left-p2">{{entiretyData.planYearRentForecast}}</p>
            <p class="w-left-p3">平均签约租金:{{entiretyData.avgSignRent}}元/月/m²
              <span
                v-if="entiretyData.targetComparison > 0"
                class="icon-jiantou"
              ></span>
              <span
                v-if="entiretyData.targetComparison < 0"
                class="icon-jiantou2"
              ></span>
            </p>
            <div class="w-left-div1">
              <p class="w-div1-number">{{entiretyData.brandSignedNum}}</p>
              <p>已 签 约 品 牌 数 量</p>
            </div>
            <div class="w-left-div1">
              <p class="w-div1-number">{{entiretyData.plannedBrandsNum}}</p>
              <p>落 位 计 划 品 牌 数 量</p>
            </div>
            <div class="w-left-div1">
              <p class="w-div1-number">{{entiretyData.announcedNum}}</p>
              <p>待 招 商 品 牌 数 量</p>
            </div>
          </div>
          <div class="w-forecast-floor-content-right">
            <div class="w-forecast-floor-content-right-head">
              <span class="w-title-head">{{gradeIdx === 'drop' ? '落位品牌级次' : '签约品牌级次'}}分析</span>
              <div class="w-head-tabs">
                <span
                  :class="[gradeIdx === 'drop' ? 'active' : '']"
                  class="w-head-drop"
                  @click="gradeClick('drop')"
                >落位品牌级次</span>
                <span
                  :class="[gradeIdx === 'singed' ? 'active' : '']"
                  class="w-head-singed"
                  @click="gradeClick('singed')"
                >签约品牌级次</span>
              </div>
            </div>
            <div class="w-forecast-floor-content-right-content">
              <div
                id="bar-chart"
                v-show="noBarChart"
              ></div>
              <div
                class="ml-50 chart-box"
                v-show="!noBarChart"
              >
                <div class="no-sign-brand">
                  <img
                    src="@/assets/images/public/not-plandata.png"
                    alt=""
                  >
                  <p>暂无相关数据</p>
                </div>
              </div>
              <div class="w-chart-table">
                <div class="w-table-head">
                  <span>品牌级次</span>
                  <span>品牌数量</span>
                  <span>数量占比</span>
                </div>
                <div class="w-is-scroll">
                  <div
                    class="w-table-body"
                    v-for="(item, index) in planTableList"
                    :key="index"
                  >
                    <span> <i :style="{'background': colors[index]}"></i> {{item.industry}}</span>
                    <span>{{item.num}}</span>
                    <span>{{item.percent + '%'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-forecast-floor-bottom">
          <div class="w-forecast-floor-bottom-content">
            <div class="w-forecast-floor-content-right-head">
              <span class="w-title-head">方案业态数据分析</span>
              <div class="w-head-tabs">
                <span
                  @click="tabType(0)"
                  :class="[typeIndex === 0 ? 'active': '']"
                  class="w-head-drop"
                >方案业态分析</span>
                <span
                  @click="tabType(1)"
                  :class="[typeIndex === 1 ? 'active': '']"
                  class="w-head-singed"
                >方案租金分析</span>
              </div>
            </div>
            <div class="w-select-tabs-content">
              <div
                class="w-select-tabs-content-one"
                v-if="typeIndex === 0"
              >
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down"
                    v-model="selectTypeStr"
                    @change="changeType"
                    placeholder="请选择"
                    size='medium'
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down"
                    v-model="berthType"
                    @change="changeBerth"
                    placeholder="请选择"
                    size='medium'
                  >
                    <el-option
                      v-for="item in berthList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="w-screen-text"
                  v-if="selectType === 1"
                >当前项目总建筑面积： <i> {{echartsObj.buildingAreaTotal}}</i>m²</div>
                <div
                  class="w-screen-text"
                  v-if="selectType === 2"
                >当前项目总计铺位数： <i> {{echartsObj.berthTotal}}</i>个</div>
              </div>
              <div
                class="w-select-tabs-content-one"
                v-if="typeIndex === 1"
              >
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down"
                    v-model="rentType"
                    @change="rentChange"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in rentList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="w-screen-text">平均签约租金：
                  <i> {{echartsObj.signRentTotal}}</i>元/月/m²
                  <span style="margin-left: 24px;">平均目标租金：<i>{{echartsObj.goalRentTotal}}</i>元/月/m²</span>
                </div>
              </div>
            </div>
            <div id="chart"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-floor-data">
      <div class="w-floor-data-head">
        <h3 class="w-title-gradients">方案楼层数据
          <el-tooltip
            class="item"
            popper-class="planTooltip"
            effect="dark"
            placement="bottom"
          >
            <span class="el-icon-question"></span>
            <div slot="content">一个铺位多个落位计划品牌时，以首选落位品牌为基准进行分析</div>
          </el-tooltip>
        </h3>
        <div class="w-floor-list w-input-sty">
          <!-- <span
            v-for="item in floorList"
            :key="item.value"
            :class="[floorIdx === item.value ? 'active' : '']"
            @click="selectFloor(item.value)">{{item.label}}</span> -->
          <el-cascader
            popper-class="black-select-panel"
            :options="floorList"
            v-model="selectFloorVal"
            @change="changeFloor"
            ref="myCascader"
            size="medium"
            filterable
          ></el-cascader>
        </div>
      </div>
      <div class="w-floor-data-content">
        <div class="w-floor-data-top">
          <div class="w-floor-top-left">
            <div class="w-rent-data">
              <p class="w-p1">楼层方案年度租金收益预测（万） <span>{{overviewData.floorYearRentForecast}}</span> </p>
              <p class="w-p2">楼层签约平均租金
                <i> {{overviewData.avgSignRent ? overviewData.avgSignRent : '-'}} </i>元/月/m²
                <em v-if="overviewData.avgSignRent">
                  {{overviewData.targetComparison===0?'':overviewData.targetComparison>0?'+'+overviewData.targetComparison+'%':overviewData.targetComparison+'%'}}
                  <i
                    v-if="overviewData.targetComparison > 0"
                    class="icon-jiantou"
                  ></i>
                  <i
                    v-if="overviewData.targetComparison < 0"
                    class="icon-jiantou2"
                  ></i>
                </em>
              </p>
            </div>
            <div class="w-rent-number">
              <div class="w-number-item">
                <p class="w-p1">{{overviewData.PlanningNum}}</p>
                <p class="w-p2">落位计划品牌数量</p>
                <p class="w-liner"></p>
                <p class="w-p4">落位面积：{{overviewData.PlanningArea}}m²</p>
              </div>
              <div class="w-number-item">
                <p class="w-p1">{{overviewData.signedNum}}</p>
                <p class="w-p2">签约品牌数量</p>
                <p class="w-liner"></p>
                <p class="w-p4">签约面积：{{overviewData.signedArea}}m²</p>
              </div>
              <div class="w-number-item">
                <p class="w-p1">{{overviewData.merchantsNum}}</p>
                <p class="w-p2">在招商品牌数量</p>
                <p class="w-liner"></p>
                <p class="w-p4">招商面积：{{overviewData.merchantsArea}}m²</p>
              </div>
            </div>
          </div>
          <div class="w-floor-top-right">
            <span class="w-right-title">{{building}}{{floor}}基础楼层数据</span>
            <div class="w-each-floor-data">
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="bottom"
              >
                <div class="w-each-item">
                  <p class="w-p1"><span class="icon-progress1"></span>楼层总面积</p>
                  <p class="w-p2">{{overviewData.floorArea}}<span>㎡</span></p>
                  <div class="w-progress">
                    <span
                      :style="{bottom: overviewData.floorAreaRatio + '%'}"
                      class="w-percentage"
                    >{{overviewData.floorAreaRatio}}%</span>
                    <span
                      class="w-colour-bar"
                      :style="{height: overviewData.floorAreaRatio + '%'}"
                    ></span>
                  </div>
                </div>
                <div slot="content">本楼层面积占比=本楼层总建筑面积 / 项目总建筑面积</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="bottom"
              >
                <div class="w-each-item">
                  <p class="w-p1"><span class="icon-progress2"></span>楼层总铺位</p>
                  <p class="w-p2">{{overviewData.floorBerthNum}}<span>间</span></p>
                  <div class="w-progress">
                    <span
                      :style="{bottom: overviewData.floorBerthNumRatio + '%'}"
                      class="w-percentage"
                    >{{overviewData.floorBerthNumRatio}}%</span>
                    <span
                      :style="{height: overviewData.floorBerthNumRatio + '%'}"
                      class="w-colour-bar"
                    ></span>
                  </div>
                </div>
                <div slot="content">本楼层铺位占比=本楼层总铺位数 / 项目总铺位数</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="bottom"
              >
                <div class="w-each-item">
                  <p class="w-p1"><span class="icon-progress3"></span>楼层年度目标租金</p>
                  <p class="w-p2">{{overviewData.floorYearGoalRent}}<span>万</span></p>
                  <div class="w-progress">
                    <span
                      :style="{bottom: overviewData.floorYearGoalRentRatio + '%'}"
                      class="w-percentage"
                    >{{overviewData.floorYearGoalRentRatio}}%</span>
                    <span
                      class="w-colour-bar"
                      :style="{height: overviewData.floorYearGoalRentRatio + '%'}"
                    ></span>
                  </div>
                </div>
                <div slot="content">本楼层年度目标租金占比=本楼层年度目标租金 / 项目年度目标租金</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="bottom"
              >
                <div class="w-each-item">
                  <p class="w-p1"><span class="icon-progress4"></span>楼层平均目标租金</p>
                  <p class="w-p2">{{overviewData.avgGoalRent}}<span>元/月/m²</span></p>
                  <div
                    class="w-progress2"
                    v-if="overviewData.avgGoalRentRatio >= 0"
                  >
                    <span
                      class="w-colour-bar"
                      :style="{height: overviewData.avgGoalRentRatio ? overviewData.avgGoalRentRatio : 0}"
                    >
                      <span class="w-percentage">{{overviewData.avgGoalRentRatio ? overviewData.avgGoalRentRatio : 0}}%</span>
                    </span>
                  </div>
                  <!-- 减少样式 -->
                  <div
                    class="w-progress2"
                    v-else
                  >
                    <span
                      class="w-colour-bar2"
                      :style="{height: Math.abs(overviewData.avgGoalRentRatio) + '%'}"
                    >
                      <span class="w-percentage">{{overviewData.avgGoalRentRatio}}%</span>
                    </span>
                  </div>
                </div>
                <div slot="content">本楼层平均目标租金相比“项目平均目标租金”=（本楼层平均目标租金-项目平均目标租金）/ 项目平均目标租金</div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="w-floor-data-bottom-wrap">
          <div class="w-floor-data-bottom">
            <div class="w-floor-head">
              <span class="w-title-head">{{building}}{{floor}}{{investmentIdx === 'drop' ? '业态招商' : '招商进度'}}占比分析</span>
              <div class="w-head-tabs">
                <span
                  @click="investmentType('drop')"
                  class="w-head-drop"
                  :class="[investmentIdx === 'drop' ? 'active' : '']"
                >按业态落位</span>
                <span
                  @click="investmentType('singed')"
                  class="w-head-singed"
                  :class="[investmentIdx === 'singed' ? 'active' : '']"
                >按招商进度</span>
              </div>
            </div>
            <div
              class="w-table-content"
              v-if="investmentIdx === 'drop'"
            >
              <div class="w-table-head">
                <span class="w-type-no-border">
                  <el-select
                    popper-class="w-block-select-down"
                    v-model="selectTypeStr2"
                    @change="changeTbaleType"
                    placeholder="请选择"
                  >
                    <template v-for="(item, inx) in typeList">
                      <el-option
                        v-if="inx !== 2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </template>
                  </el-select>
                </span>
                <span>面积(㎡)</span>
                <span>面积占比</span>
                <span>平均目标租金(元/月/㎡)</span>
                <span>落位品牌数</span>
                <span>数量占比</span>
              </div>
              <div class="w-is-scroll">
                <div class="w-table-body">
                  <div
                    v-for="item in floorTableList"
                    :key="item.id"
                    class="w-table-item"
                  >
                    <span>{{item.yetainame}}</span>
                    <span>{{item.area}}</span>
                    <span>{{item.areaPie}}%</span>
                    <span>{{item.goalRent}}</span>
                    <span>{{item.position}}</span>
                    <span>{{item.positionPie}}%</span>
                  </div>
                </div>
                <div
                  class="no-recommend-brand"
                  v-if="floorTableList && floorTableList.length === 0"
                >
                  <img
                    src="@/assets/images/public/not-plandata.png"
                    alt=""
                  >
                  <p>暂无相关数据</p>
                </div>
              </div>
            </div>
            <div
              class="w-table-content2"
              v-if="investmentIdx === 'singed'"
            >
              <div class="w-table-head">
                <span>招商进度</span>
                <span>铺位数量</span>
                <span>数量占比</span>
                <span>面积(㎡)</span>
                <span>面积占比</span>
                <span>平均目标租金(元/月/㎡)</span>
                <span>签约平均租金(元/月/㎡)</span>
              </div>
              <div class="w-is-scroll">
                <div class="w-table-body">
                  <div
                    v-for="item in overviewData.mechantList"
                    :key="item.id"
                    class="w-table-item"
                  >
                    <span>{{item.yetainame}}</span>
                    <span>{{item.berthCount}}</span>
                    <span>{{item.berthPie}}%</span>
                    <span>{{item.area}}</span>
                    <span>{{item.areaPie}}%</span>
                    <span>{{item.goalRent}}</span>
                    <span>{{item.signRent}}</span>
                  </div>
                </div>
                <div
                  class="no-recommend-brand"
                  v-if="overviewData.mechantList && overviewData.mechantList.length === 0"
                >
                  <img
                    src="@/assets/images/public/not-plandata.png"
                    alt=""
                  >
                  <p>暂无相关数据</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import api from '@/api'
export default {
  data () {
    return {
      project: '',
      typeIndex: 0,
      selectTypeStr: '一级业态',
      selectType: 1,
      type: 1,
      typeList: [{ label: '一级业态', value: 1 }, { label: '二级业态', value: 2 }, { label: '主次力店', value: 3 }],
      berthType: '按铺位面积',
      berthList: [{ label: '按铺位面积', value: 1 }, { label: '按铺位数量', value: 2 }],
      rentType: '楼层租金达成',
      rentList: [{ label: '楼层租金达成', value: 3 }, { label: '业态租金达成', value: 4 }, { label: '主次力店租金达成', value: 6 }],
      echartsObj: {},
      typeText: '业态数据',
      floorList: [],
      selectFloorVal: [],
      floorTableList: [],
      building: '',
      floor: '',
      overviewData: {},
      selectTypeStr2: '一级业态',
      investmentIdx: 'drop',
      gradeIdx: 'drop',
      noBarChart: true,
      planTableList: [],
      loading: true,
      entiretyData: {},
      colors: ['#34A6F5', '#F78121', '#FFCC00', '#00D7D5', '#0FC967', '#8ED409', '#166EE9', '#6b6e7e']
    }
  },
  props: {
    projectId: {
      type: Number
    },
    planId: {
      type: Number
    }
  },
  watch: {
    planId (newVal, oldVal) {
      this.loading = true
      this.getFloorList()
      this.tabType(0)
      this.gradeClick('drop')
      // this.getPlanEntirety()
    }
  },
  mounted () {
    this.getFloorList()
    this.getFormatPie()
    this.getDropChartFunc()
    // this.getPlanEntirety()
  },
  methods: {
    // 获取方案整体数据
    getPlanEntirety () {
      this.axios.post(api.getPlanEntiretyData, { projectId: this.projectId, planId: this.planId })
        .then((res) => {
          this.entiretyData = res.data.data
        })
    },
    getFloorList () {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.floorList = res.data.data.map((item) => {
            item.children = item.children.map((option) => {
              const tem = {
                value: option.value,
                label: option.label
              }
              return tem
            })
            return item
          })
          this.selectFloorVal.push(this.floorList[0].label, this.floorList[0].children[0].label)
          this.getFloorData(this.floorList[0].label, this.floorList[0].children[0].label)
          this.building = this.floorList[0].label
          this.floor = this.floorList[0].children[0].label
          this.loading = false
        })
    },
    getFormatPie () {
      this.axios.post(api.getFormatAreaPie,
        {
          projectId: this.projectId,
          planId: this.planId,
          selectType: this.selectType,
          type: this.type
        })
        .then((res) => {
          this.echartsObj = res.data.data
          this.setBarData(res.data.data)
        })
    },
    tabType (index) {
      this.loading = true
      this.typeIndex = index
      if (index === 0) {
        this.type = 1
        this.selectType = 1
        this.getFormatPie()
        this.typeText = '业态数据'
        this.selectTypeStr = '一级业态'
        this.berthType = '按铺位面积'
      } else if (index === 1) {
        this.selectType = 3
        this.type = 0
        this.getFormatPie()
        this.typeText = '租金数据'
        this.rentType = '楼层租金达成'
      }
    },
    changeType (val) {
      this.loading = true
      if (typeof (val) !== 'string') {
        this.type = val
        this.getFormatPie()
      }
    },
    changeBerth (val) {
      this.loading = true
      if (typeof (val) !== 'string') {
        this.selectType = val
        this.getFormatPie()
      }
    },
    rentChange (val) {
      this.loading = true
      if (typeof (val) !== 'string') {
        this.selectType = val
        this.getFormatPie()
      }
    },
    gradeClick (type) {
      this.loading = true
      this.gradeIdx = type
      if (type === 'drop') {
        this.getDropChartFunc()
      } else {
        this.axios.post(api.getSignedChart,
          {
            projectId: this.projectId,
            type: 1,
            planId: this.planId
          })
          .then((res) => {
            if (res.data.code === 0) {
              if (res.data.data.labels.length !== 0) {
                this.noBarChart = true
                this.$nextTick(() => {
                  this.buildsignBrandChart('bar-chart', res.data.data)
                })
              } else {
                this.noBarChart = false
              }
            }
          })
        this.axios.post(api.listSignedNum,
          {
            projectId: this.projectId,
            type: 1,
            planId: this.planId
          })
          .then((res) => {
            this.planTableList = res.data.data
          })
        this.loading = false
      }
    },
    getDropChartFunc () {
      this.axios.post(api.getDropChart,
        {
          projectId: this.projectId,
          type: 1
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (res.data.data.labels.length !== 0) {
              this.noBarChart = true
              this.$nextTick(() => {
                this.buildsignBrandChart('bar-chart', res.data.data)
              })
            } else {
              this.noBarChart = false
            }
          }
          this.loading = false
        })
      this.axios.post(api.listPlanNum,
        {
          projectId: this.projectId,
          type: 1
        })
        .then((res) => {
          this.planTableList = res.data.data
        })
    },
    // 方案楼层数据
    changeFloor (val) {
      this.loading = true
      this.building = val[0]
      this.floor = val[1]
      this.getFloorData(val[0], val[1])
    },
    // 获取楼层概览
    getFloorData (building, floor) {
      this.axios.post(api.getFloorDataView,
        {
          projectId: this.projectId,
          floor: floor,
          building: building,
          planId: this.planId
        })
        .then((res) => {
          this.overviewData = res.data.data
          this.overviewData.avgGoalRentRatio = res.data.data.avgGoalRentRatio - 0
          this.floorTableList = this.overviewData.listOne
          this.loading = false
        })
    },
    investmentType (type) {
      this.investmentIdx = type
    },
    changeTbaleType (val) {
      this.loading = true
      if (val === 1) {
        this.floorTableList = this.overviewData.listOne
      } else {
        this.floorTableList = this.overviewData.list
      }
      this.loading = false
    },
    buildsignBrandChart (dom, data) {
      const that = this
      const list = data.labels.map((item, index) => {
        return {
          value: data.values[index],
          name: item
        }
      })
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        tooltip: {
          trigger: 'item',
          textStyle: {
            align: 'left'
          },
          formatter (params) {
            if (that.gradeIdx === 'drop') {
              return params.name + ' <br/> 落位 ' + params.value + ' 个<br /> 数量占比：' + params.percent + '%'
            } else {
              return params.name + ' <br/> 签约 ' + params.value + ' 个<br /> 数量占比：' + params.percent + '%'
            }
          }
        },
        legend: {
          icon: 'circle',
          bottom: 10,
          itemGap: 20,
          textStyle: {
            color: '#fff',
            padding: [0, 0, -3, -2]
          },
          data: data.labels
          // data: ['餐饮', '儿童亲子', '文体娱', '生活服务', '零售', '跨界集合店', '其他类型']
        },
        color: this.colors,
        series: [
          {
            type: 'pie',
            center: ['50%', '40%'],
            radius: ['35%', '65%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: list
          }
        ]
      }
      myChart.setOption(option)
      this.loading = false
    },
    setBarData (data) {
      const that = this
      const myChart = echarts.init(document.getElementById('chart'))
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            if (that.selectType === 1) {
              return data.yetais[params[0].dataIndex] + '<br/>' + data.data[0].name + '：面积：' + data.data[0].area[params[0].dataIndex] + '㎡' + ' 占比：' + data.data[0].percent[params[0].dataIndex] + '%' + '<br/>' + data.data[1].name + '：面积：' + data.data[1].area[params[0].dataIndex] + '㎡' + ' 占比：' + data.data[1].percent[params[0].dataIndex] + '%'
            } else if (that.selectType === 2) {
              return data.yetais[params[0].dataIndex] + '<br/>' + data.data[0].name + '：铺位个数：' + data.data[0].area[params[0].dataIndex] + ' 占比：' + data.data[0].percent[params[0].dataIndex] + '%' + '<br/>' + data.data[1].name + '：铺位个数：' + data.data[1].area[params[0].dataIndex] + ' 占比：' + data.data[1].percent[params[0].dataIndex] + '%'
            } else if (that.selectType === 3) {
              return data.yetais[params[0].dataIndex] + '<br/>' + data.data[0].name + '：' + data.data[0].area[params[0].dataIndex] + '元/月/㎡' + '<br/>' + data.data[1].name + '：' + data.data[1].area[params[0].dataIndex] + '元/月/㎡'
            } else if (that.selectType === 4) {
              return data.yetais[params[0].dataIndex] + '<br/>' + data.data[0].name + '：' + data.data[0].area[params[0].dataIndex] + '元/月/㎡' + '<br/>' + data.data[1].name + '：' + data.data[1].area[params[0].dataIndex] + '元/月/㎡'
            } else if (that.selectType === 6) {
              return data.yetais[params[0].dataIndex] + '<br/>' + data.data[0].name + '：' + data.data[0].area[params[0].dataIndex] + '元/月/㎡' + '<br/>' + data.data[1].name + '：' + data.data[1].area[params[0].dataIndex] + '元/月/㎡'
            } else {
              return data.yetais[params[0].dataIndex] + '<br/>' + '已签约品牌数量' + '：' + data.data[0].area[params[0].dataIndex] + '<br/>' + '方案落位品牌数量' + '：' + data.data[1].area[params[0].dataIndex]
            }
          }
        },
        legend: {
          show: true,
          data: [data.data[0].name, data.data[1].name],
          textStyle: {
            color: '#fff'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'square',
          right: 'center',
          bottom: '0'
        },
        color: ['#16DA64', '#26CBE9'],
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              algin: 'left',
              // rotate: 40,
              interval: 0
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,1)'
              }
            },
            axisTick: 'false',
            data: data.yetais
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,1)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            },
            axisLabel: {
              show: true,
              formatter: this.selectType === 1 ? '{value}%' : '{value}'
            }
          }
        ],
        series: [
          {
            name: data.data[0].name,
            barWidth: '16px',
            type: 'bar',
            data: (that.type === 1 || that.type === 2 || that.type === 3) ? data.data[0].percent : data.data[0].area
          },
          {
            name: data.data[1].name,
            barWidth: '16px',
            type: 'bar',
            data: (that.type === 1 || that.type === 2 || that.type === 3) ? data.data[1].percent : data.data[1].area
          }
        ]
      }
      myChart.setOption(option)
      this.loading = false
    }
  }
}
</script>

<style lang="stylus">
.w-scheme-data-wrap
  .w-forecast-floor-bottom
    .w-select-tabs-content-one
      .el-select .el-input__inner
        width 160px
</style>
<style lang="stylus" scoped>
.w-scheme-data-wrap
  margin-top 16px
  .w-plan-data
    padding-top 24px
    background-color #272930
    padding-bottom 27px
  .w-forecast-floor
    .w-forecast-floor-top
      display flex
      margin-top 17px
      padding 0 24px
      .w-forecast-floor-content-left
        width 324px
        height 421px
        padding-top 25px
        background-color #272930
        border 1px solid rgba(255, 255, 255, 0.1)
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 0px 3px 3px 3px
        text-align center
        box-sizing border-box
        .w-left-p1
          font-size 16px
          font-weight 500
          color #FFFFFF
        .w-left-p2
          font-size 44px
          font-weight bold
          color #FFFFFF
          margin 5px 0
        .w-left-p3
          font-size 12px
          font-weight 400
          color #FFFFFF
          margin-bottom 35px
        .w-left-div1
          width 218px
          height 68px
          border 1px dashed rgba(255, 255, 255, 0.23)
          border-radius 3px
          margin auto
          margin-bottom 12px
          p
            font-size 14px
            font-weight 500
            color #FFFFFF
          .w-div1-number
            font-size 28px
            font-weight bold
            margin-top 6px
      .w-forecast-floor-content-right
        width calc(100% - 340px)
        height 421px
        padding-top 19px
        background #272930
        border 1px solid rgba(255, 255, 255, 0.1)
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 0px 3px 3px 3px
        text-align center
        box-sizing border-box
        margin-left 16px
        .w-forecast-floor-content-right-head
          display flex
          .w-title-head
            font-size 16px
            font-weight 500
            color #FFFFFF
            margin-left 24px
          .w-head-tabs
            margin-left auto
            margin-right 27px
            span
              display inline-block
              width 118px
              height 32px
              line-height 32px
              text-align center
              border 1px solid rgba(255, 255, 255, 0.23)
              font-size 14px
              font-weight bold
              color rgba(255, 255, 255, 0.23)
              cursor pointer
              &.active
                background-color #FFA134
                color #fff
            .w-head-drop
              border-radius 3px 0px 0px 3px
              margin-right -1px
            .w-head-singed
              border-radius 0px 3px 3px 0px
        .w-forecast-floor-content-right-content
          margin-top 24px
          display flex
          #bar-chart
            width 70%
            height 300px
          .w-chart-table
            margin-left auto
            width 600px
            border 1px solid rgba(255, 255, 255, 0.05)
            margin-right 27px
            .w-table-head
              height 42px
              line-height 42px
              background-color rgba(255, 255, 255, 0.05)
              span
                display inline-block
                width 33%
                color #A5A5A7
                font-weight 14px
            .w-is-scroll
              height 260px
              overflow-y auto
            .w-table-body
              height 51px
              line-height 51px
              border-bottom 1px solid #353539
              span
                display inline-block
                width 33%
                font-size 14px
                color #fff
                i
                  display inline-block
                  width 10px
                  height 10px
                  border-radius 50%
.w-forecast-floor-bottom
  padding 0 24px
  margin-top 16px
  .w-forecast-floor-bottom-content
    padding-top 25px
    height 470px
    background-color #272930
    border 1px solid rgba(255, 255, 255, 0.1)
    box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
    border-radius 0px 3px 3px 3px
    box-sizing border-box
    .w-forecast-floor-content-right-head
      display flex
      .w-title-head
        font-size 16px
        font-weight 500
        color #FFFFFF
        margin-left 24px
      .w-head-tabs
        margin-left auto
        margin-right 27px
        span
          display inline-block
          width 118px
          height 32px
          line-height 32px
          text-align center
          border 1px solid rgba(255, 255, 255, 0.23)
          font-size 14px
          font-weight bold
          color rgba(255, 255, 255, 0.23)
          cursor pointer
          &.active
            background-color #FFA134
            color #fff
        .w-head-drop
          border-radius 3px 0px 0px 3px
          margin-right -1px
        .w-head-singed
          border-radius 0px 3px 3px 0px
    .w-select-tabs-content-one
      display flex
      height 32px
      line-height 32px
      margin-top 30px
      .w-select-screen-border
        margin-left 24px
      .w-screen-text
        margin-left 24px
        font-size 14px
        color #ffffff
        font-weight 500
        i
          color #FFA134
    #chart
      width 100%
      height 340px
.w-floor-data
  padding-top 24px
  background-color #272930
  padding-bottom 27px
  .w-floor-data-head
    display flex
    .w-floor-list
      margin-left auto
      margin-right 24px
      span
        display inline-block
        width 42px
        height 32px
        border 1px solid rgba(255, 255, 255, 0.23)
        text-align center
        line-height 32px
        margin-right -1px
        font-size 14px
        font-weight bold
        color rgba(255, 255, 255, 0.23)
        cursor pointer
        &.active
          background-color #FFA134
          color #fff
        &:first-child
          border-radius 3px 0px 0px 3px
        &:last-child
          border-radius 0px 3px 3px 0px
  .w-floor-data-content
    .w-floor-data-top
      display flex
      padding 0 24px
      margin-top 16px
      .w-floor-top-left
        width 634px
        background-color #272930
        height 294px
        background #272930
        border 1px solid rgba(255, 255, 255, 0.1)
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 0px 3px 3px 3px
        .w-rent-data
          height 130px
          border-bottom 1px solid rgba(255, 255, 255, 0.1)
          box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
          border-radius 0px 3px 3px 3px
          .w-p1
            display flex
            font-size 16px
            font-weight 500
            color #FFFFFF
            line-height 36px
            padding-top 30px
            padding-left 32px
            span
              font-size 44px
              font-weight bold
          .w-p2
            font-size 12px
            font-weight 400
            color #FFFFFF
            padding-left 32px
            i
              font-size 18px
            em
              font-size 12px
            .icon-decline
              color #FF5A06
            .icon-rise
              color #07C43E
        .w-rent-number
          display flex
          height 164px
          align-items center
          .w-number-item
            flex 1
            text-align center
            padding 0 50px
            .w-p1
              font-size 30px
              font-weight bold
              color #FFFFFF
            .w-p2
              font-size 12px
              font-weight 500
              color #FFFFFF
            .w-liner
              border 1px dashed rgba(255, 255, 255, 0.5)
              margin 6px 0
            .w-p4
              font-size 12px
              font-weight 500
              color #FFFFFF
      .w-floor-top-right
        width calc(100% - 650px)
        height 294px
        margin-left 16px
        background #272930
        border 1px solid rgba(255, 255, 255, 0.1)
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 0px 3px 3px 3px
        padding-top 24px
        box-sizing border-box
        .w-right-title
          color #fff
          margin-left 24px
        .w-each-floor-data
          display flex
          height 240px
          align-items center
          .w-each-item
            width 25%
            .w-p1
              color #A5A5A7
              font-size 14px
              font-weight 400
              margin-left 50px
              span
                font-size 22px
                color #fff
                vertical-align middle
                margin-right 10px
            .w-p2
              font-size 20px
              font-weight 500
              color #fff
              margin-left 80px
              span
                font-size 14px
            .w-progress
              position relative
              width 56px
              height 112px
              background #353539
              margin auto
              margin-top 28px
              .w-colour-bar
                position absolute
                left 0
                bottom 0
                height 40%
                background-color #20D6FE
                width 100%
              .w-percentage
                position absolute
                left 0
                width 50px
                left 50%
                margin-left -25px
                bottom 5%
                font-size 14px
                font-weight 500
                color #FFFFFF
                text-align center
            .w-progress2
              position relative
              width 56px
              height 112px
              background #353539
              margin auto
              margin-top 28px
              .w-colour-bar
                position absolute
                left 0
                bottom 0
                // height 40%
                background-color #07C43E
                width 100%
                .w-percentage
                  position absolute
                  left 0
                  width 50px
                  left 50%
                  margin-left -25px
                  bottom 0px
                  font-size 14px
                  font-weight 500
                  color #FFFFFF
                  text-align center
              .w-colour-bar2
                position absolute
                left 0
                top 0
                // height 40%
                background-color #FF5A06
                width 100%
                .w-percentage
                  position absolute
                  left 0
                  width 50px
                  left 50%
                  margin-left -25px
                  top 10px
                  font-size 14px
                  font-weight 500
                  color #FFFFFF
                  text-align center
    .w-floor-data-bottom-wrap
      padding 0 24px
      margin-top 16px
      .w-floor-data-bottom
        height 421px
        padding-top 19px
        background #272930
        border 1px solid rgba(255, 255, 255, 0.1)
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 0px 3px 3px 3px
        box-sizing border-box
        .w-floor-head
          display flex
          .w-title-head
            font-size 16px
            font-weight 500
            color #FFFFFF
            margin-left 24px
          .w-head-tabs
            margin-left auto
            margin-right 27px
            span
              display inline-block
              width 118px
              height 32px
              line-height 32px
              text-align center
              border 1px solid rgba(255, 255, 255, 0.23)
              font-size 14px
              font-weight bold
              color rgba(255, 255, 255, 0.23)
              cursor pointer
              &.active
                background-color #FFA134
                color #fff
            .w-head-drop
              border-radius 3px 0px 0px 3px
              margin-right -1px
            .w-head-singed
              border-radius 0px 3px 3px 0px
.w-floor-data-bottom-wrap
  .w-table-content
    margin-top 30px
    padding-left 24px
    // padding-right 24px
    .w-table-head
      height 53px
      line-height 53px
      margin-right 24px
      border-bottom 1px solid rgba(255, 255, 255, 0.1)
      span
        display inline-block
        width 16.3%
        font-size 14px
        font-weight bold
        color rgba(255, 255, 255, 0.5)
    .w-is-scroll
      height 270px
      overflow-y auto
      .w-table-body
        padding-right 24px
        .w-table-item
          height 53px
          line-height 53px
          border-bottom 1px solid rgba(255, 255, 255, 0.1)
          span
            display inline-block
            width 16.3%
            font-size 14px
            font-weight bold
            color rgba(255, 255, 255, 1)
  .w-table-content2
    margin-top 30px
    padding-left 24px
    // padding-right 24px
    .w-table-head
      height 53px
      line-height 53px
      margin-right 24px
      border-bottom 1px solid rgba(255, 255, 255, 0.1)
      span
        display inline-block
        width 14%
        font-size 14px
        font-weight bold
        color rgba(255, 255, 255, 0.5)
    .w-is-scroll
      height 270px
      overflow-y auto
      .w-table-body
        padding-right 24px
        .w-table-item
          height 53px
          line-height 53px
          border-bottom 1px solid rgba(255, 255, 255, 0.1)
          span
            display inline-block
            width 14%
            font-size 14px
            font-weight bold
            color rgba(255, 255, 255, 1)
</style>
